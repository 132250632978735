import { createSlice } from '@reduxjs/toolkit';
import api from '../services/index';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    measurements: {},
  },
  reducers: {
    setMeasurements: (state, action) => {
      state.measurements = action.payload;
    },
  },
});

export const getMeasuments =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get('/measurements', { params });
      dispatch(setMeasurements(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getCarbonOffset =
  ({ form_session_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/carbon_offset/${form_session_id}`, { params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setMeasurements } = dashboardSlice.actions;
export default dashboardSlice.reducer;
