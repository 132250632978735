import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OrganisationContext } from '../context/organisationContext';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { initModal } from '../helpers/utils';
import Switch from '../components/common/switch';
import { createMeasurementNft } from '../store/features/calculatorSlice';
import { AddMeasurementBlockchainWrapper } from './modal.styled';

function AddMeasurementBlockchain() {
  const { user } = useSelector(state => state.user);
  const { setModal, modal } = useContext(OrganisationContext);
  const pageRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { measurement, session_id, onCreate } = modal.content;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    company_name: user?.organizations?.[0]?.name,
    measurement_name: measurement.name,
  });
  const [error, setError] = useState({});

  const checkAutomationErrors = () => {
    if (!data?.company_name?.trim() || !data?.measurement_name?.trim()) {
      setError({
        company_name: !data?.company_name?.trim(),
        measurement_name: !data?.measurement_name?.trim(),
      });
      dispatch(addToast({ error: true, text: 'Please fill all data' }));
      return true;
    }
    return false;
  };

  const onDone = () => {
    if (loading || checkAutomationErrors()) {
      return;
    }
    setLoading(true);
    dispatch(
      createMeasurementNft({
        request: {
          company_name: data.anonymise ? data.company_name.replace(/./g, '*') : data.company_name,
          measurement_name: data.measurement_name,
          session: {
            id: session_id,
          },
          mint: true,
        },
        measurement_id: measurement.id,
      }),
    )
      .then(() => {
        dispatch(addToast({ error: false, text: 'Measurement nft created', id: 'measurement-nft' }));
        onCreate();
        setModal(initModal);
      })
      .catch(error => {
        dispatch(addToast({ error: true, text: 'Something went wrong while creating nft', id: 'measurement-nft' }));
      })
      .then(() => setLoading(false));
  };

  const onChangeEnable = () => {
    setData({ ...data, anonymise: !data.anonymise });
  };

  return (
    <CommonPopup
      popupTitle="Add your measurement to the XRP Blockchain"
      confirmButtonProps={{ label: 'Mint XRP', size: 'large' }}
      cancelButtonProps={{ size: 'large' }}
      onCancel={() => setModal(initModal)}
      onConfirm={onDone}
      disabled={loading}
      subChildren={
        <div className="flex mt-6">
          <label className="text-center font-14 lato-400-text">
            Once you add to the Blockchain your measure will be publicly accessible and locked for changes
          </label>
        </div>
      }>
      <AddMeasurementBlockchainWrapper className="flex-column items-center w-full">
        <div className="w-full flex-column mt-6">
          <div className="flex items-center justify-between mb-2">
            <label className="purple-50-text font-12">{t('COMPANY_NAME')}</label>
            <div className="flex items-center">
              <p className="regular-text font-14 mr-4">Anonymise</p>
              <Switch className="mr-2" onClick={() => onChangeEnable()} enabled={data?.anonymise} disabled={false} />
            </div>
          </div>
          <input
            autoComplete="turnoff"
            className={`small-input ${error?.name && 'error-border'}`}
            onChange={e => {
              setData({ ...data, company_name: e.target.value });
              setError({ ...error, company_name: false });
            }}
            placeholder="Enter company name"
            value={data?.company_name || ''}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="purple-50-text mb-2 font-12">{t('MEASUREMENT_NAME')}</label>
          <input
            autoComplete="turnoff"
            className={`small-input ${error?.name && 'error-border'}`}
            onChange={e => {
              setData({ ...data, measurement_name: e.target.value });
              setError({ ...error, measurement_name: false });
            }}
            placeholder="Enter measurement name"
            value={data?.measurement_name || ''}
          />
        </div>
      </AddMeasurementBlockchainWrapper>
    </CommonPopup>
  );
}

export default AddMeasurementBlockchain;
