import moment from 'moment';
import { getItemFromLocalStorage } from './localstorage';

export const emailRegEx = /^[A-Za-z0-9][-A-Z-a-z0-9.!#$%&'*+-=?^_`{|}~\/]+@([-A-Z-a-z0-9]+\.)+[A-Za-z]{2,5}$/;

export const initModal = {
  type: null,
  content: {},
};

export const defaultFirstPanel = {
  id: 'TIME_PERIOD',
  key: 'TIME_PERIOD',
  name: 'Time Period',
  default: true,
  guidance:
    'Most companies will want to measure by a calendar or financial year. This acts as a benchmark on which to reduce and offset their emissions.',
};

export const capitalize = str => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export function capitalizeFirstLetterOfWords(str) {
  return str.replace(/\b\w/g, char => char.toUpperCase());
}

export const formatText = (text, replaceWith = '-') => {
  if (!text) return '';
  return text.toLowerCase().replaceAll('_', replaceWith);
};

export const uppercase = str => {
  if (!str) return '';
  return str.toUpperCase();
};

export const lowercase = str => {
  if (!str) return '';
  return str.toLowerCase();
};

export const handleCopyClick = async url => {
  try {
    await navigator.clipboard.writeText(url);
    return true;
  } catch (error) {
    console.error('Failed to copy:', error);
    return false;
  }
};

export const getUsersPermissions = () => {
  const user = getItemFromLocalStorage('user');
  return user?.api_tokens?.permissions;
};

export const countMatchesInRegex = (regex, str) => {
  return ((str || '').match(regex) || []).length;
};

export const getUserInitials = user => {
  const { forename, surname, name } = user;
  const userName = name?.split(' ') || null;

  return userName
    ? `${userName?.[0]?.charAt(0)?.toUpperCase() || ''}${userName?.[1]?.charAt(0)?.toUpperCase() || ''}`
    : forename || surname
    ? `${forename?.charAt(0)?.toUpperCase() || ''}${surname?.charAt(0)?.toUpperCase() || ''}`
    : '';
};

export const getContactValueByType = (contactTypes, field, field_value, return_field = 'value') => {
  const contactTypeByField = contactTypes.find(type => type[field] === field_value);
  return contactTypeByField?.[return_field] || '';
};

export const getCurrencySymbol = currency => {
  switch (currency) {
    case 'USD':
    case 'usd':
      return '$';
    case 'GBP':
    case 'gbp':
      return '£';
    case 'EUR':
    case 'eur':
      return '€';
    default:
      return '$';
  }
};

export const getFormattedDate = (dateInUnix, format = 'DD/MM/YYYY') => {
  return moment(dateInUnix * 1000)
    .utc()
    .format(format);
};

export const getDateDifference = (date, compareWith = null, unit = 'days') => {
  const compareWithMoment = compareWith ? moment(compareWith * 1000) : moment();

  return date ? compareWithMoment.utc(false).diff(moment(date * 1000).utc(false), unit) : 0;
};

export const onlyNumbers = /^\d+$/;

export const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const getFormattedCurrency = (number, currency) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency || 'GBP',
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(number);
};

export const getFormattedNumber = (number, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'decimal',
    minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as 2,500.1)
    maximumFractionDigits, // (causes 2500.99 to be printed as 2,501)
  });
  return formatter.format(number);
};

export const getImageThumbnail = mediaData => {
  const { url, media = [] } = mediaData;
  const thumbnail = media ? media?.at(0) : null;
  return thumbnail?.url || url;
};

export const isJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getErrorDescription = (error, defaultErrorText = '') => {
  if (!error) return '';
  return error?.response?.data?.error_description || defaultErrorText;
};
