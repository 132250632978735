import Axios from 'axios';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import HidePassword from '../../assets/images/hide-password.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import AuthContainer from '../../components/common/auth-container';
import Button from '../../components/common/button/button';
import { emailRegEx } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import { setUser } from '../../store/features/userSlice';
import OtpInput from './otp-input';
import { ReactComponent as RightIcon } from '../../assets/images/arrow-right.svg';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [showError, setShowError] = useState(false);
  const [active, setActive] = useState(false);
  const [currentStep, setCurrentStep] = useState('EMAIL');
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [otpValue, setOtpValue] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordActive, setPasswordActive] = useState();
  const [authenticationToken, setAuthenticationToken] = useState('');
  const [passwordError, setPasswordError] = useState();

  const resetPassword = async () => {
    if (active) {
      await Axios.post(`${process.env.REACT_APP_API_URL}/password/otp`, {
        destination: email,
        destination_type: 'EMAIL',
      });
      setCurrentStep('OTP');
    }
  };

  const sendOtp = async () => {
    if (otpValue && otpValue.length === 6) {
      try {
        const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/password/otp/validate`, {
          destination: email,
          code: otpValue,
          destination_type: 'EMAIL',
        });
        setAuthenticationToken(data.access_token);
        setCurrentStep('PASSWORD');
      } catch (error) {
        const errorData = error?.response?.data;
        if (errorData?.error_code === 'BAD_REQUEST') {
          setIsOtpValid(false);
        }
      }
    }
  };

  const changeConfirmPassword = value => {
    setConfirmPassword(value);
    setShowError(false);
  };

  const changePassword = value => {
    setPassword(value);
    setShowError(false);
  };

  const resendEmail = async () => {
    try {
      await Axios.post(`${process.env.REACT_APP_API_URL}/password/otp`, {
        destination: email,
        destination_type: 'EMAIL',
      });
    } catch (e) {
      console.log(e);
    }
  };

  const activate = async () => {
    if (passwordActive) {
      if (password !== confirmPassword) setPasswordError('Passwords do not match');
      else {
        Axios.put(
          `${process.env.REACT_APP_API_URL}/users/password`,
          {
            password: password,
          },
          { headers: { authorization: `Bearer ${authenticationToken}` } },
        )
          .then(() => {
            login(email, password);
          })
          .catch(() => {
            setPasswordError('Something happened');
          });
      }
    }
  };

  const login = async (email, password) => {
    if (active) navigate('/login');
  };

  const changeEmail = value => {
    setEmail(value);
    setShowError(false);
  };

  useEffect(() => {
    setActive(email && email.length > 2);
  }, [email]);

  useEffect(() => {
    setPasswordActive(password && confirmPassword && password.length > 2 && confirmPassword.length > 2);
  }, [password, confirmPassword]);

  return (
    <AuthContainer>
      <ForgotPasswordWrapper className="flex-column flex-1">
        {currentStep === 'EMAIL' ? (
          <div className="flex-column forgot-password-form gap-10">
            <div className="flex flex-column gap-2">
              <label className="quicksand-700-text font-32">{t('FORGOTTEN_PASSWORD_TITLE')}</label>
              <label className="quicksand-400-text font-14">{t('FORGOTTEN_PASSWORD_TEXT')}</label>
            </div>
            <div className="flex gap-5 form flex-column items-center">
              <div className="w-full flex-column relative gap-5px">
                <input
                  autoComplete="off"
                  className={`input password-input  ${showError && 'error-border'}`}
                  placeholder={t('EMAIL')}
                  onChange={({ target: { value } }) => changeEmail(value)}
                />
              </div>
            </div>
            <div className="flex gap-5 items-center">
              <Button
                className="secondary specified-width flex items-center"
                width="56px"
                size="large"
                borderRadius="16px"
                fontWeight="600"
                icon={<RightIcon className="rotate-180" height={16} width={16} />}
                onClick={() => navigate('/login')}
              />
              <Button
                borderRadius="16px"
                className={classNames('primary', !(active || showError) && 'disabled')}
                size="large"
                label={t('RESET')}
                onClick={resetPassword}
              />
            </div>
          </div>
        ) : currentStep === 'OTP' ? (
          <div className="flex-column forgot-password-form gap-10">
            <div className="flex flex-column gap-2">
              <label className="quicksand-700-text font-32">{t('OTP_PASSWORD_TITLE')}</label>
              <label className="quicksand-400-text font-14">{t('OTP_PASSWORD_TEXT')}</label>
            </div>
            <div className="flex gap-5 form flex-column">
              <OtpInput
                className={'otp-input'}
                containerClass={'otp-content'}
                errorStyle={'error-style'}
                hasErrored={!isOtpValid}
                inputStyle={'input-style'}
                isInputNum={true}
                onChange={setOtpValue}
                placeholder="000000"
                value={otpValue}
              />
              {isOtpValid && (
                <div className="flex otp-error pxy-1">
                  <label className="quicksand-400-text font-14 ml-2">
                    Incorrect code - please check your code is correct and try again
                  </label>
                </div>
              )}
              <div className="flex items-center cursor re-send">
                <label className="quicksand-500-text font-16 ml-2 green-text" onClick={resendEmail}>
                  Re-send email
                </label>
              </div>
            </div>
            <div className="flex gap-5 items-center">
              <Button
                className="secondary specified-width flex items-center"
                width="56px"
                size="large"
                borderRadius="16px"
                fontWeight="600"
                icon={<RightIcon className="rotate-180" height={16} width={16} />}
                onClick={() => setCurrentStep('EMAIL')}
              />
              <Button
                borderRadius="16px"
                className={classNames('primary', !(active || showError) && 'disabled')}
                size="large"
                label={t('SUBMIT')}
                onClick={sendOtp}
              />
            </div>
          </div>
        ) : (
          <div className="flex-column login-form w-full gap-10">
            <div className="flex flex-column gap-2">
              <label className="quicksand-700-text font-32">{t('RESET_YOUR_PASSWORD')}</label>
            </div>
            {passwordError && (
              <div className="flex password-error">
                <label className="quicksand-400-text">{passwordError}</label>
              </div>
            )}
            <div className="w-full flex gap-5 form flex-column items-center">
              <div className="w-full flex-column relative gap-5px">
                <div className="relative flex w-full">
                  <input
                    autoComplete="off"
                    className={`input password-input w-full ${showError && 'error-border'}`}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('PASSWORD')}
                    onChange={({ target: { value } }) => changePassword(value)}
                  />
                  <img
                    className="show-password"
                    src={showPassword ? HidePassword : ShowPassword}
                    alt="show password"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
              </div>
              <div className="w-full flex-column relative gap-5px">
                <div className="relative flex w-full">
                  <input
                    autoComplete="off"
                    className={`input password-input w-full ${showError && 'error-border'}`}
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder={t('CONFIRM_PASSWORD')}
                    onChange={({ target: { value } }) => changeConfirmPassword(value)}
                  />
                  <img
                    className="show-password"
                    src={showConfirmPassword ? HidePassword : ShowPassword}
                    alt="show password"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </div>
              </div>
            </div>
            {/* Bottom Button  */}
            <div className="w-full flex gap-5 items-center">
              <Button
                className="secondary specified-width flex items-center"
                width="56px"
                size="large"
                borderRadius="16px"
                fontWeight="600"
                icon={<RightIcon className="rotate-180" height={16} width={16} />}
                onClick={() => setCurrentStep('OTP')}
              />
              <Button
                borderRadius="16px"
                className={classNames('primary', !(active || showError) && 'disabled')}
                size="large"
                label={t('SET_PASSWORD')}
                onClick={activate}
              />
            </div>
            {/* end */}
          </div>
        )}
      </ForgotPasswordWrapper>
    </AuthContainer>
  );
};

const ForgotPasswordWrapper = styled.div`
  .forgot-password-form {
    max-width: 500px;
  }
  .otp-input {
    margin: 0px 8px;

    .input-style {
      width: 50px;
      height: 50px;
      border: unset;
      text-align: center;
      background: #ffffff;
      box-shadow: 5px 5px 30px rgb(5 49 73 / 10%);
      border-radius: 8px;
      font-size: 16px;
      color: #053149;
      font-family: Roboto Regular;

      :focus-visible {
        outline: 0px;
      }

      ::-webkit-input-placeholder {
        opacity: 0.3;
        color: #053149;
      }
    }

    .error-style {
      border: 1px solid #ff5b5b;
    }
  }

  .show-password {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 16px;
    top: 20px;
    cursor: pointer;
  }
}
`;

export default ForgotPassword;
