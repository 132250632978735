import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import CurrencyInput from 'react-currency-input-field';

const variants = {
  size_40: { height: '40px', 'font-size': '16px', 'border-radius': '12px' },
  size_64: { height: '64px', 'font-size': '24px', 'border-radius': '16px' },
};

const ComponentInput = ({
  id,
  name,
  showName = false,
  is_required = false,
  value,
  default_value = '',
  onChange = () => {},
  placeholder,
  className = '',
  error,
  disabled = false,
  error_text = '',
  sub_label = '',
  extra_label = '',
  sub_name = '',
  type = 'text',
  variant = 'size_40',
  font = null,
  decimal_places,
  use1000_separator,
  unit_type,
  currency,
  measurementSystem,
  previous,
  next,
  maxLength,
}) => {
  const isTextArea = type === 'textarea';
  const getUnitType = type => {
    if (type === 'CURRENCY') {
      if (currency === 'USD') return '$';
      else if (currency === 'GBP') return '£';
      else if (currency === 'EUR') return '€';
      else return currency;
    } else if (type === 'WEIGHT') {
      return measurementSystem === 'METRIC' ? 'kg' : 'lb';
    } else if (type === 'VOLUME') {
      return measurementSystem === 'METRIC' ? 'ltr' : 'gal';
    } else if (type === 'DISTANCE') {
      return measurementSystem === 'METRIC' ? 'km' : 'mi';
    } else if (type === 'POWER') {
      return 'kW';
    } else if (type === 'AREA') {
      return measurementSystem === 'METRIC' ? 'm2' : 'ft2';
    } else {
      return '';
    }
  };
  return (
    <InputElementWrapper
      className={classNames('flex items-center col-gap-2', className)}
      font={font}
      variant={variants[variant]}
      sub_label={sub_label}
      style={{
        borderTopLeftRadius: previous?.component_type === 'NUMERIC' ? '0' : '16px',
        borderTopRightRadius: previous?.component_type === 'NUMERIC' ? '0' : '16px',
        borderBottomLeftRadius: next?.component_type === 'NUMERIC' ? '0' : '16px',
        borderBottomRightRadius: next?.component_type === 'NUMERIC' ? '0' : '16px',
        marginBottom: next?.component_type === 'NUMERIC' ? '' : '24px',
        padding: next?.component_type === 'NUMERIC' ? '16px 24px 0 24px' : '16px 24px',
      }}>
      {name && showName && (
        <div className="mb-1 one-line name-container" style={{ width: `${maxLength / 2}em` }}>
          <span className="lato-500-text natural-900-text font-16">{name}</span>
          {sub_name && <span className="lato-400-text natural-400-text ml-1">{sub_name}</span>}
          {is_required && <span className="required-star ml-1">*</span>}
        </div>
      )}
      <div className="relative flex flex-1">
        {isTextArea ? (
          <textarea
            className={classNames(
              'lato-400-text natural-900-text input-element flex-1 textarea-element',
              error && 'input-error',
            )}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={({ target: { value } }) => onChange(value)}
          />
        ) : type === 'number' ? (
          <div className="flex flex-1 items-center">
            <div className="input-element flex items-center currency-input flex-1">
              <CurrencyInput
                key={id}
                className={classNames('lato-400-text natural-900-text flex-1', error && 'input-error')}
                decimalsLimit={decimal_places || 0}
                disableGroupSeparators={!use1000_separator}
                onValueChange={value => onChange(value)}
                step={10}
                value={value}
                placeholder={placeholder}
              />
              {unit_type && <p className="lato-400-text natural-900-text font-16 ml-4">{getUnitType(unit_type)}</p>}
            </div>
          </div>
        ) : (
          <input
            key={id}
            className={classNames('lato-400-text natural-900-text flex-1 input-element', error && 'input-error')}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            type={type}
            onChange={({ target: { value } }) => onChange(value)}
          />
        )}
        {extra_label && (
          <span className="extra-text lato-400-text font-14 natural-900-text absolute">{extra_label}</span>
        )}
      </div>
      {error && error_text && (
        <div>
          <span className="lato-400-text font-12 error-text">{error_text}</span>
        </div>
      )}
    </InputElementWrapper>
  );
};

const InputElementWrapper = styled('div')`
  background: #f6f6f6;
  .input-element {
    background: #ffffff;
    outline: none;
    font-size: ${({ font }) => (font?.size ? `${font.size}px` : '16px')};
    border: 1px solid ${({ theme }) => theme.natural_300};
    border-radius: ${({ variant }) => variant['border-radius']};
    padding: 8px 16px;
    padding-right: ${({ sub_label }) => (sub_label ? '44px' : '16px')};

    &::placeholder {
      color: ${({ theme }) => theme.natural_300};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .currency-input {
    padding-right: 16px;
    input {
      border: none;
      background: transparent;
    }
  }

  .name-container {
    min-width: 140px;
  }

  .textarea-element {
    height: 104px;
    resize: none;
  }

  .extra-text {
    right: 20px;
    padding: 12px 0;
  }

  .input-error {
    border-color: ${({ theme }) => theme.error_500};

    &:hover {
      border-color: ${({ theme }) => theme.error_500};
    }
  }

  input:disabled {
    background-color: ${({ theme }) => theme.natural_100};
    border-color: ${({ theme }) => theme.natural_300};
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default ComponentInput;
