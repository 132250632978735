import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getFormattedNumber, uppercase } from '../../helpers/utils';

const ScopeEmission = ({ scope, scopesTotal }) => {
  const { t } = useTranslation();
  const { groups, total: totalScope, level } = scope || {};
  const total = totalScope / 1000;

  const percentageOfGroup = groupTotal => {
    return (groupTotal / total) * 100 || 0;
  };

  const percentageOfTotal = groupTotal => {
    return (groupTotal / (scopesTotal / 1000)) * 100 || 0;
  };

  return (
    <ScopeEmissionWrapper className="flex-column border radius-3">
      <div className="header-container flex items-center">
        <label className="lato-500-text font-12">{uppercase(t('SCOPE_LEVEL', { level }))}</label>
        <label className="lato-500-text font-12">{uppercase(t('PERCENT_OF_EMISSION_OF_SCOPE'))}</label>
        <label className="lato-500-text font-12">{uppercase(t('PERCENT_OF_EMISSION_OF_TOTAL'))}</label>
        <label className="lato-700-text font-12">{uppercase(t('TONNES_CO2'))}</label>
      </div>
      {groups?.map(({ name, total }, index) => (
        <div className={classNames('flex items-center data-container', index !== 0 && 'border-top')} key={index}>
          <label className="lato-400-text font-16">{name}</label>
          <label className="lato-400-text font-16">{getFormattedNumber(percentageOfGroup(total / 1000))}%</label>
          <label className="lato-400-text font-16">{getFormattedNumber(percentageOfTotal(total / 1000))}%</label>
          <label className="lato-400-text font-16">{getFormattedNumber(total / 1000)}</label>
        </div>
      ))}
    </ScopeEmissionWrapper>
  );
};

const TotalEmissionContent = ({ carbonOffset }) => {
  const { scopes } = carbonOffset || { scopes: [] };
  const total = scopes?.map(s => s.total).reduce((s, a) => a + s, 0);

  return (
    <TotalEmissionContentWrapper className="mt-10 flex-column row-gap-6 overflow-auto">
      {scopes?.map((scope, index) => (
        <ScopeEmission scope={scope} key={index} scopesTotal={total} />
      ))}
    </TotalEmissionContentWrapper>
  );
};

const TotalEmissionContentWrapper = styled.div``;

const ScopeEmissionWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 24px;
    padding: 0 16px;
  }

  .header-container {
    min-height: 40px;
    background-color: ${({ theme }) => theme.purple_50_O_15};
    border-radius: 12px 12px 0 0;
  }

  .data-container {
    min-height: 48px;
  }
`;

export default TotalEmissionContent;
