import React, { useState } from 'react';
import styled from 'styled-components';
import DashboardContent from './dashboard-content';
import DashboardHeader from './dashboard-header';
import { useLocation } from 'react-router-dom';

const Dashboard = () => {
  const { state } = useLocation();
  const [selectedMeasurement, setSelectedMeasurement] = useState(state?.measurement);

  return (
    <DashboardWrapper className="flex-column flex-1 overflow-auto pxy-6">
      <DashboardHeader selectedMeasurement={selectedMeasurement} setSelectedMeasurement={setSelectedMeasurement} />
      <DashboardContent selectedMeasurement={selectedMeasurement} />
    </DashboardWrapper>
  );
};

const DashboardWrapper = styled.div``;

export default Dashboard;
