export const light = {
  primary: '#4851f4',
  background: '#FBFBFB',
  navBackground: '#ffffff',
  border: '#B5B9C180',
  text: '#053149',
  selectedText: '#2496FF',
  sidebarBackground: '#FFFFFF',
  RegularText: '#16192C',

  white: '#FFFFFF',

  primaryBlue: '#2496FF',
  secondaryBlue: '#E8F4FF',
  black: '#020819',

  greyText: '#A3ABB1',
  lightGrey: '#D2E5ED',
  primaryGrey: '#F4F7F9',

  primaryGreen: '#31ECE1',
  lightGreyBackground: '#F4F7F9',

  greyText2: '#73868C',

  additionalRedDark: '#EF4444',
  additionalRed: '#FF8888',
  additionalRed2: '#FFF0F0',
  additionalYellow: '#FAF4E7',
  additionalYellow2: '#FFC773',
  additionalGreen: '#A1D9A0',
  additionalViolet: '#E6EEFF',
  darkGrey: '#BBC3C6',

  purple: '#E5E8FF',
  ligthGreen: '#DCFCD7',
  lightYellow: '#FFF3D3',

  borderColor: '#E3EEF3',

  lightViolet: '#8AB2FF',

  focus_border: '#D3EAFE',

  purple_500: '#8324FF',

  natural_50: '#FAFAFA',
  natural_150: '#F0F0F0',
  natural_100: '#F5F5F5',
  natural_200: '#E5E5E5',
  natural_300: '#D4D4D4',
  natural_400: '#A3A3A3',
  natural_500: '#737373',
  natural_600: '#525252',
  natural_700: '#404040',
  natural_900: '#171717',

  primary_10: 'rgba(36, 203, 177, 0.1)',
  primary_50: '#24cbb14d',
  primary_300: '#E9FAF7',
  primary_500: '#24CBB1',
  primary_600: '#007FF5',

  orange_50: '#FFFBEB',
  orange_500: '#F59E0B',

  success_50: '#F0FDF4',
  success_500: '#22C55E',

  error_50: 'rgba(254, 242, 242, 1)',
  error_500: '#EF4444',

  purple_50: '#AABAB7',

  purple_50_O_15: 'rgba(170, 186, 183, 0.15)',

  grey_300: '#D9D9D9',
  grey_400: '#B6B6B6',
  grey_500: '#EFEFEF',
  grey_600: '#858585',
};
export const dark = {
  primary: '#4851f4',
  background: 'linear-gradient(180deg, #FFFFFF 64.56%, #F0F4F5 103.67%);',
  navBackground: '#ffffff',
  border: '#deebf1',
  text: '#053149',
  selectedText: '#2496FF',
  sidebarBackground: '#FFFFFF',

  white: '#FFFFFF',

  primaryBlue: '#2496FF',
  secondaryBlue: '#E8F4FF',
  darkBlue: '#053149',

  greyText: '#A3ABB1',
  lightGrey: '#D2E5ED',
  primaryGrey: '#F4F7F9',

  primaryGreen: '#31ECE1',
  lightGreyBackground: '#F4F7F9',

  greyText2: '#73868C',

  additionalRed: '#FF8888',
  additionalRed2: '#FFF0F0',
  additionalYellow: '#FAF4E7',
  additionalYellow2: '#FFC773',
  additionalGreen: '#A1D9A0',
  additionalViolet: '#E6EEFF',

  purple: '#E5E8FF',
  ligthGreen: '#DCFCD7',
  lightYellow: '#FFF3D3',

  borderColor: '#E3EEF3',

  natural_400: '#A3A3A3',
  primary_50: '#EFF6FF',

  error_50: 'rgba(254, 242, 242, 1)',
  error_500: '#EF4444',
};
