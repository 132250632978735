import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CalculatorWidgets from './calculator-widget';
import FlowProgress from './flow-progress';
import TimePeriodSelect from './time-period-select';

const CalculatorRight = ({ changeNextPanel, currentWidget, setCurrentWidget, end }) => {
  const navigate = useNavigate();

  const { formWidgets, formSessionData, measurement } = useSelector(state => state.calculator);
  const { measurements } = useSelector(state => state.dashboard);

  const [widgetData, setWidgetData] = useState({});
  useEffect(() => {
    const panelWidgetComponentIds =
      formSessionData?.map(({ panel_widget_component }) => panel_widget_component.id) || [];
    if (panelWidgetComponentIds.length > 0) {
      const widgetDataTest = formWidgets
        ?.filter(w => !widgetData[w.id])
        .reduce((acc, widget) => {
          const { id, components } = widget;
          const widgetData =
            components
              ?.filter(
                ({ is_visible, component_type, id: component_id }) =>
                  is_visible && component_type !== 'HEADER' && panelWidgetComponentIds.includes(component_id),
              )
              ?.map(({ id, component_type, lexicon }) => {
                if (component_type === 'CHOOSER') {
                  const idLexicon = `${lexicon}.id`;
                  const nameLexicon = `${lexicon}.name`;
                  return {
                    [id]: {
                      id: formSessionData.find(
                        ({ panel_widget_component, lexicon: dataLexicon }) =>
                          panel_widget_component.id === id && dataLexicon === idLexicon,
                      )?.submitted_data,
                      name: formSessionData.find(
                        ({ panel_widget_component, lexicon: dataLexicon }) =>
                          panel_widget_component.id === id && dataLexicon === nameLexicon,
                      )?.submitted_data,
                    },
                  };
                }
                return {
                  [id]: formSessionData.find(({ panel_widget_component }) => panel_widget_component.id === id)
                    ?.submitted_data,
                };
              }) || [];
          return { ...acc, [id]: Object.assign({}, ...widgetData) };
        }, {});
      setWidgetData({ ...widgetData, ...widgetDataTest });
    }
  }, [formWidgets, formSessionData]);
  const onPreviousWidgetClick = widget => {
    const selectedWidget = widget?.id ? widget : currentWidget;
    const { parents } = selectedWidget;
    if (parents?.length > 0) {
      let isFlowValid = false;
      parents.forEach(({ component_id, component_value, parent_widget_id, ...rest }) => {
        if (!isFlowValid) {
          const widgetDataValue = widgetData[parent_widget_id] || {};
          if (widgetDataValue[component_id]?.id === component_value) {
            isFlowValid = { ...rest, component_id, component_value, parent_widget_id };
          } else if (component_value === 'ANY' && widgetDataValue[component_id]) {
            isFlowValid = { ...rest, component_id, component_value, parent_widget_id };
          }
        }
      });
      if (isFlowValid) {
        const previousWidget = formWidgets.find(widget => widget.id === isFlowValid.parent_widget_id);
        setCurrentWidget(previousWidget);
      } else {
        const currentIndex = formWidgets.findIndex(widget => widget.id === selectedWidget.id);
        const previousIndex = formWidgets[currentIndex - 1];

        if (previousIndex) {
          setCurrentWidget(previousIndex);
        } else {
          navigate('/dashboard');
        }
      }
    } else {
      const currentIndex = formWidgets.findIndex(widget => widget.id === selectedWidget.id);
      const previousIndex = formWidgets[currentIndex - 1];

      if (previousIndex) {
        setCurrentWidget(previousIndex);
      } else {
        navigate('/dashboard');
      }
    }
  };

  const onNextWidgetClick = widget => {
    const selectedWidget = widget?.id ? widget : currentWidget;
    const currentIndex = formWidgets.findIndex(widget => widget.id === selectedWidget.id);

    const { flows } = selectedWidget;
    if (flows?.length > 0) {
      let isFlowValid = false;
      flows.forEach(({ component_id, component_value, ...rest }) => {
        if (!isFlowValid) {
          const widgetDataValue = widgetData[selectedWidget.id] || {};
          if (widgetDataValue[component_id]?.id === component_value) {
            isFlowValid = { ...rest, component_id, component_value };
          } else if (component_value === 'ANY' && widgetDataValue[component_id]) {
            isFlowValid = { ...rest, component_id, component_value };
          }
        }
      });
      if (isFlowValid) {
        const isSamePanel = isFlowValid.panel_id === selectedWidget.panel.id;
        if (isSamePanel) {
          const nextWidget = formWidgets.find(widget => widget.id === isFlowValid.widget_id);
          setCurrentWidget(nextWidget);
          return;
        } else {
          changeNextPanel(isFlowValid.panel_id, isFlowValid.widget_id);
          return;
        }
      } else {
        const nextIndex = formWidgets[currentIndex + 1];
        if (!nextIndex) {
          changeNextPanel();
          return;
        } else {
          setCurrentWidget(nextIndex);
        }
      }
    } else {
      const nextIndex = formWidgets[currentIndex + 1];
      if (!nextIndex) {
        changeNextPanel();
        return;
      } else {
        setCurrentWidget(nextIndex);
      }
    }
  };

  const onBackToSummary = () => {
    navigate('/dashboard', { replace: true, state: { measurement: measurement } });
  };

  return (
    <CalculatorRightWrapper className="flex-column">
      <FlowProgress widget={currentWidget} />
      {currentWidget?.key === 'TIME_PERIOD' && (
        <TimePeriodSelect
          setWidgetData={setWidgetData}
          widgetData={widgetData}
          onNextWidgetClick={onNextWidgetClick}
          onPreviousWidgetClick={onPreviousWidgetClick}
          onBackToSummary={onBackToSummary}
        />
      )}
      {currentWidget?.key !== 'TIME_PERIOD' && (
        <CalculatorWidgets
          widget={currentWidget}
          setWidgetData={setWidgetData}
          widgetData={widgetData}
          className="mt-10 row-gap-4"
          componentClassName=""
          onNextWidgetClick={onNextWidgetClick}
          onPreviousWidgetClick={onPreviousWidgetClick}
          onBackToSummary={onBackToSummary}
          end={end}
          firstTime={measurements?.content?.length === 0}
        />
      )}
    </CalculatorRightWrapper>
  );
};

const CalculatorRightWrapper = styled.div`
  min-width: 360px;
  width: 50%;

  .currency-metric-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  @media only screen and (max-width: 1200px) {
    .device-scale-component {
      flex-direction: column;
    }
  }
`;

export default CalculatorRight;
