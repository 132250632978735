import ReactECharts from 'echarts-for-react';
import React, { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

const DonutPieChart = ({ dataSet, tooltipTitle, tooltipValueFormatter = value => value }) => {
  const theme = useTheme();

  const customColors = useMemo(() => [theme.grey_400, theme.grey_600, theme.black, theme.grey_600], []);

  const getOption = useCallback(() => {
    const customTooltipFormatter = params => {
      const name = params?.data?.name;
      const value = params?.data?.value;

      return `
            <div style="min-width:120px; text-align:center; border-radius: 12px">
              <p class="lato-400-text py-1 px-2" style="background-color: ${
                theme.black
              }; color: white; border-radius: 12px 12px 0 0;">
                ${name}
              </p>
              <div class="px-2 py-1 flex-column">
                <label class="flex justify-center items-center flex-1 lato-700-text font-16">${tooltipValueFormatter(
                  value,
                )}</label>
                <label class="flex justify-center items-center flex-1 lato-400-text">${tooltipTitle}</label>
              </div>
            </div>
        `;
    };

    return {
      tooltip: {
        trigger: 'item',
        formatter: customTooltipFormatter,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: 12,
        borderWidth: 0,
        padding: 0,
      },
      legend: {
        show: false,
      },
      series: [
        {
          type: 'pie',
          center: ['50%', '40%'],
          radius: ['60%', '90%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: dataSet.map((item, index) => ({
            ...item,
            itemStyle: {
              color: customColors[index],
            },
          })),
        },
      ],
    };
  }, [dataSet]);

  return (
    <ReactECharts option={getOption()} style={{ width: '100%', height: '400px' }} notMerge={true} lazyUpdate={true} />
  );
};

export default DonutPieChart;
