import Axios from 'axios';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HidePassword from '../../assets/images/hide-password.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import AuthContainer from '../../components/common/auth-container';
import Button from '../../components/common/button/button';
import { emailRegEx } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import { setUser } from '../../store/features/userSlice';
import uuid from 'react-uuid';
import { getMeasuments } from '../../store/features/dashboardSlice';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [active, setActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginStatus, setLoginStatus] = useState('');
  const [emailError, setEmailError] = useState(false);

  const isEmailValid = email => {
    return emailRegEx.test(email);
  };

  const onLogin = async event => {
    if (active) {
      if (!isEmailValid(email)) {
        setEmailError(true);
        return;
      }
      try {
        event.preventDefault();
        const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/authentication`, {
          identity: email,
          password: password,
        });
        localStorage.setItem('user', JSON.stringify(data));
        dispatch(setUser(data));
        const measurements = await dispatch(getMeasuments({ params: { page: 0, size: 1 } }));
        if (measurements?.content?.length === 0) navigate(`/measurements/${uuid()}`, { state: { create: true } });
        else navigate('/dashboard');
      } catch (e) {
        setShowError(true);
      }
    }
  };

  const changePassword = value => {
    setPassword(value);
    setShowError(false);
  };

  const changeEmail = email => {
    setEmail(email);
    setShowError(false);
    setEmailError(email ? !isEmailValid(email) : false);
  };

  useEffect(() => {
    setActive(email && password && email.length > 2 && password.length > 2);
  }, [email, password]);

  return (
    <AuthContainer>
      <LoginWrapper className="flex-1">
        <div className="flex-column login-form w-full gap-10">
          {/* Heading */}
          <div className="flex flex-column gap-2">
            <label className="quicksand-700-text font-32">{t('WELCOME_BACK')}</label>
          </div>
          {/* end */}
          {(loginStatus === 'FAILURE' || loginStatus === 'INACTIVE') && (
            <div className="error-message-alert items-center justify-between flex flex-1">
              <label className="quicksand-600-text font-16 error-text py-3">
                {loginStatus === 'INACTIVE' ? t('ACCOUNT_DEACTIVATE_DETAIL_TEXT') : t('INCORRECT_EMAIL_PASSWORD')}
              </label>
              <CloseIcon className="cursor" onClick={() => setLoginStatus('')} />
            </div>
          )}
          {showError && (
            <div className="flex error-message-alert items-center justify-between flex-1">
              <label className="quicksand-600-text font-16 error-text py-3">{t('INCORRECT_EMAIL_PASSWORD')}</label>
              <CloseIcon className="cursor" onClick={() => setShowError(false)} />
            </div>
          )}
          <div className="w-full flex gap-5 form flex-column items-center">
            <div className="w-full flex-column gap-5px">
              <input
                autoComplete="off"
                placeholder={t('EMAIL')}
                onChange={({ target: { value } }) => changeEmail(value)}
                className={classNames('input', (emailError || showError) && 'error-border')}
              />
            </div>
            <div className="w-full flex-column relative gap-5px">
              <div className="relative flex w-full">
                <input
                  autoComplete="off"
                  className={`input password-input w-full ${showError && 'error-border'}`}
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('PASSWORD')}
                  onChange={({ target: { value } }) => changePassword(value)}
                />
                <img
                  className="show-password"
                  src={showPassword ? HidePassword : ShowPassword}
                  alt="show password"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            </div>
          </div>
          {/* Bottom Button  */}
          <div className="w-full flex flex-column gap-5 items-center">
            <Button
              borderRadius="16px"
              className={classNames('primary', !(active || showError || emailError) && 'disabled')}
              size="large"
              label={t('LOGIN')}
              onClick={onLogin}
            />
            <a href="/password" className="lato-600-text primary-500-text">
              Forgot your password?
            </a>
          </div>
          {/* end */}
        </div>
      </LoginWrapper>
    </AuthContainer>
  );
};

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;

  .form {
    .password-input {
      padding-right: 48px;
    }
    .show-password {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 16px;
      top: 20px;
      cursor: pointer;
    }

    a {
      text-decoration: none;
      align-self: center;
    }
  }
`;

export default Login;
