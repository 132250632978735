import { configureStore } from '@reduxjs/toolkit';
import calculatorSlice from './features/calculatorSlice';
import reloadSlice from './features/reloadSlice';
import toastSlice from './features/toastSlice';
import userSlice from './features/userSlice';
import dashboardSlice from './features/dashboardSlice';

export default configureStore({
  reducer: {
    reload: reloadSlice,
    toast: toastSlice,
    user: userSlice,
    calculator: calculatorSlice,
    dashboard: dashboardSlice,
  },
  devTools: process.env.REACT_APP_STAGE === 'development',
});
