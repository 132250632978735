import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { getUsersPermissions } from '../../helpers/utils';
import Modal from '../../popup';
import history from '../../routes/history';
import { addToast } from '../../store/features/toastSlice';
import CalculatorWrapper from '../calculator-wrapper';
import Dashboard from '../dashboard';
import TotalEmission from '../total-emission';

const ROUTE_PATHS = [
  {
    id: 'DASHBOARD',
    path: '/dashboard',
    Component: Dashboard,
  },
  {
    id: 'DASHBOARD_MEASUREMENT',
    path: '/measurement/:measurement_id/session/:form_session_id',
    Component: TotalEmission,
  },
  {
    id: 'CALCULATOR',
    path: '/measurements/:measurement_id',
    Component: CalculatorWrapper,
  },
];

const Main = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { toasts } = useSelector(state => state.toast);

  const [selectedPath, setSelectedPath] = useState(location.pathname);
  const [modal, setModal] = useState({
    type: null,
    content: {},
  });

  const permissions = getUsersPermissions();

  const hasPermission = permission => {
    return permissions.includes(permission);
  };

  const hasMultiplePermission = permissionList => {
    return (
      !permissionList ||
      (permissionList.length > 0 && permissionList.find(permission => permissions.includes(permission)))
    );
  };

  const checkPermission = (action, permission) => {
    if (!permission || permissions.includes(permission)) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: 'You do not have permission to perform this action',
          }),
        );
  };

  useEffect(() => {
    if (history.action === 'POP' || history.action === 'REPLACE' || history.action === 'PUSH') {
      setSelectedPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    navigator.serviceWorker.getRegistrations().then(regs => regs.forEach(reg => reg.update()));
  }, [location]);

  const checkMultiplePermission = (action, permissionList) => {
    if (
      !permissionList ||
      (permissionList.length > 0 && permissionList.find(permission => permissions.includes(permission)))
    ) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: 'You do not have permission to perform this action',
          }),
        );
  };

  return (
    <MainContentWrapper>
      <OrganisationContext.Provider
        value={{
          modal,
          setModal,
          checkPermission,
          hasPermission,
          hasMultiplePermission,
          checkMultiplePermission,
        }}>
        <div className="main">
          <Routes>
            {ROUTE_PATHS.filter(path => hasMultiplePermission(path.permission)).map(path => (
              <Route
                key={path.id}
                element={<path.Component {...path.params} path={path.path} {...path.actions} />}
                path={path.path}
              />
            ))}
            <Route
              element={
                <Navigate
                  replace
                  to={
                    ROUTE_PATHS.find(path => hasMultiplePermission(path.permission))
                      ? ROUTE_PATHS.find(path => hasMultiplePermission(path.permission)).path
                      : '/profile'
                  }
                />
              }
              path="*"
            />
          </Routes>
        </div>
        <Modal />
      </OrganisationContext.Provider>
    </MainContentWrapper>
  );
};

const MainContentWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.background};

  .main {
    width: 100%;
    display: flex;
  }
`;

export default Main;
