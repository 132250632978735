import classNames from 'classnames';
import moment from 'moment/moment';
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getErrorDescription } from '../../helpers/utils';
import {
  createMeasurements,
  updateMeasurement,
  getCalculators,
  getCurrencies,
  getIndustries,
} from '../../store/features/calculatorSlice';
import { addToast } from '../../store/features/toastSlice';
import Button from '../common/button/button';
import DateSelectorElement from '../common/date-selector/date-selector-element';
import ComponentInput from '../common/input';
import SelectDropdown from '../common/select-dropdown';
import CalculatorAction from './calculator-action';
import { OrganisationContext } from '../../context/organisationContext';

const currencyOptions = [
  { value: 'USD', label: '$' },
  { value: 'GBP', label: '£' },
];

const measurementOptions = [
  { value: 'IMPERIAL', label: 'Imperial' },
  { value: 'METRIC', label: 'Metric' },
];

const TimePeriodSelect = ({ setWidgetData, widgetData, onNextWidgetClick, onPreviousWidgetClick, onBackToSummary }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setModal } = useContext(OrganisationContext);

  const { measurement_id } = useParams();
  const isDisabled = false;

  const { formDetails, measurement, calculator, currencies, industries } = useSelector(state => state.calculator);
  const data = widgetData?.['TIME_PERIOD'] || {};

  const [startDate, setStartDate] = useState(data.startDate);
  const [endDate, setEndDate] = useState(data.endDate);
  const [name, setName] = useState(data.name);
  const [currency, setCurrency] = useState();
  const [industry, setIndustry] = useState();
  const [measurementSystem, setMeasurementSystem] = useState(data.measurementSystem || 'IMPERIAL');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (measurement?.id) {
      setStartDate(measurement?.timing?.start_time);
      setEndDate(measurement?.timing?.end_time);
      setName(measurement?.name);
      setMeasurementSystem(measurement?.measurement_system);
      setCurrency(measurement?.currency?.id);
      setIndustry(measurement?.industry?.id);
      setWidgetData({
        ...widgetData,
        TIME_PERIOD: { startDate: measurement?.timing?.start_time, endDate: measurement?.timing?.end_time },
      });
    }
  }, [measurement?.id]);

  const onLastFinancialYearClick = () => {
    // TODO: Implement last financial year logic
  };

  const onLastCalendarYearClick = () => {
    if (isDisabled || loading) {
      return;
    }
    const lastYear = moment().subtract(1, 'year');
    const start = lastYear.clone().startOf('year');
    const end = lastYear.clone().endOf('year');
    setStartDate(start.unix());
    setEndDate(end.unix());
    setWidgetData({ ...widgetData, TIME_PERIOD: { startDate: start.unix(), endDate: end.unix() } });
  };

  const onChangeDate = (date, key) => {
    setWidgetData({ ...widgetData, TIME_PERIOD: { ...data, [key]: date } });
  };

  const onChangeStartDate = date => {
    setStartDate(date);
    onChangeDate(date, 'startDate');
  };

  const onChangeEndDate = date => {
    setEndDate(date);
    onChangeDate(date, 'endDate');
  };

  const onChangeName = value => {
    setName(value);
    setWidgetData({ ...widgetData, TIME_PERIOD: { ...data, name: value } });
  };

  const onCreateMeansurement = () => {
    if (loading) {
      return;
    }
    const { id } = formDetails || {};
    const defaultRequest = {
      id: measurement_id,
      name: name ? name : 'Carbon Emission Calculation for SMEs',
      description: name
        ? name
        : `Carbon Emission Calculation for SMEs - ${moment().format('DD MMM YYYY')} - ${moment().unix()}`,
      measurement_type: 'SME',
      carbon: {
        scope1: 0,
        scope2: 0,
        scope3: 0,
      },
    };
    if (isDisabled) {
      onNextWidgetClick();
      return;
    }
    if (measurement?.id) {
      const updateMeasurementRequest = {
        ...defaultRequest,
        ...measurement,
        name: name ? name : 'Carbon Emission Calculation for SMEs',
        measurement_system: measurementSystem,
        description: name
          ? name
          : `Carbon Emission Calculation for SMEs - ${moment().format('DD MMM YYYY')} - ${moment().unix()}`,
        timing: {
          start_time: startDate,
          end_time: endDate,
        },
      };
      setLoading(true);
      dispatch(updateMeasurement({ measurement_id: measurement.id, request: updateMeasurementRequest }))
        .then(() => onNextWidgetClick())
        .catch(error => {
          const errorText = getErrorDescription(error, 'Something went wrong while updating measurements');
          dispatch(addToast({ error: true, text: errorText, id: 'measurement-update' }));
        })
        .finally(() => setLoading(false));
    } else {
      const formSessionRequest = {
        ...defaultRequest,
        measurement_system: measurementSystem,
        currency: currency ? { id: currency?.id } : null,
        industry: industry ? { id: industry?.id } : null,
        timing: {
          start_time: startDate,
          end_time: endDate,
        },
        form_session: {
          session_start: moment().unix(),
          session_end: '',
          form: {
            id: id,
          },
          form_type: '',
        },
        campaign: calculator.campaign
          ? {
              id: calculator.campaign?.id,
            }
          : null,
      };
      setLoading(true);
      dispatch(createMeasurements({ request: formSessionRequest }))
        .then(() => {
          try {
            navigate(location.pathname, { replace: true });
          } catch (e) {
            console.log(e);
          }
          setModal({
            type: 'before-start',
            content: { onNext: onNextWidgetClick },
          });
          // onNextWidgetClick();
        })
        .catch(error => {
          const errorText = getErrorDescription(error, 'Something went wrong while creating measurements');
          dispatch(addToast({ error: true, text: errorText, id: 'form-session' }));
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    dispatch(getCalculators({ tag: 'SME' }));
  }, []);

  useEffect(() => {
    dispatch(getIndustries({}));
  }, []);

  useEffect(() => {
    dispatch(getCurrencies({}));
  }, []);

  useEffect(() => {
    if (measurement) {
      if (measurement.currency)
        setCurrency({
          ...measurement.currency,
          value: measurement.currency.id,
          label: measurement.currency.currency_code,
        });
      if (measurement.industry)
        setIndustry({ ...measurement.industry, value: measurement.industry.id, label: measurement.industry.industry });
    }
  }, [measurement?.id]);

  useEffect(() => {
    if (industries && industries.length > 0 && !measurement.id) {
      setIndustry({ ...industries[0], value: industries[0].id, label: industries[0].industry });
    }
  }, [industries]);

  useEffect(() => {
    if (currencies && currencies.length > 0 && !measurement.id) {
      setCurrency({
        ...currencies[0],
        value: currencies[0].id,
        label: currencies[0].currency_code,
      });
    }
  }, [currencies]);

  return (
    <Fragment>
      <div className="flex-column mt-10 flex-1">
        <label className="quicksand-700-text font-32 mb-4">
          Specify the timeframe for which you wish to calculate emissions?
        </label>
        <ComponentInput
          font={{ size: '28' }}
          placeholder={t('NAME')}
          name={t('NAME')}
          value={name}
          onChange={onChangeName}
          disabled={isDisabled}
        />
        <div className="flex col-gap-4 mb-6">
          <DateSelectorElement
            setSelectedDate={onChangeStartDate}
            selectedDate={startDate}
            placeholder={'1 January 2024'}
            format="DD MMMM YYYY"
            maxEndDate={endDate}
            isDisabled={isDisabled}
          />
          <DateSelectorElement
            setSelectedDate={onChangeEndDate}
            selectedDate={endDate}
            placeholder={'1 December 2024'}
            format="DD MMMM YYYY"
            minStartDate={startDate}
            isDisabled={isDisabled}
          />
        </div>
        <div className="flex col-gap-2">
          <Button
            label="Last calendar year"
            className="specified-width transparent-default"
            size="average"
            width="150px"
            fontSize="14px"
            onClick={onLastCalendarYearClick}
          />
        </div>
        <div className="border pxy-4 radius-4 bg-natural-100 items-center currency-metric-container mt-12">
          <label className="lato-400-text font-16">What currency shall we use</label>
          <SelectDropdown
            options={currencies.map(c => ({ ...c, value: c.id, label: c.currency_code }))}
            onChange={option => setCurrency(option)}
            value={currency}
            isDisabled={isDisabled}
          />
          <label className="lato-400-text font-16">What Measurement system shall we use</label>
          <div className="flex items-center col-gap-3 w-full">
            {measurementOptions.map(option => (
              <Button
                key={option.value}
                label={option.label}
                className={classNames(
                  'specified-width',
                  measurementSystem === option.value ? 'primary-black' : 'transparent-default',
                )}
                size="average"
                width="112px"
                fontSize="14px"
                disabled={isDisabled}
                borderRadius="16px"
                onClick={() => setMeasurementSystem(option.value)}
              />
            ))}
          </div>
          <label className="lato-400-text font-16">What sector do you operate in?</label>
          <SelectDropdown
            options={industries.map(c => ({ ...c, value: c.id, label: c.industry }))}
            onChange={option => setIndustry(option)}
            value={industry}
            isDisabled={isDisabled}
          />
        </div>
      </div>
      <CalculatorAction
        onNextWidgetClick={onCreateMeansurement}
        onPreviousWidgetClick={onPreviousWidgetClick}
        disabled={!(startDate && endDate && name)}
        loading={loading}
        onBackToSummary={onBackToSummary}
      />
    </Fragment>
  );
};

export default TimePeriodSelect;
