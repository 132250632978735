import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as PlanerIcon } from '../../assets/images/planet.svg';

const FlowProgress = ({ widget }) => {
  const { formPanels } = useSelector(state => state.calculator);

  const selectedPanel = widget?.panel;
  const totalPanels = formPanels.length + 1;
  const selectedPanelIndex = formPanels.findIndex(panel => panel.id === selectedPanel?.id) + 2;

  const getProgressWidth = () => {
    return Math.ceil((selectedPanelIndex / totalPanels) * 100);
  };

  return (
    <FlowProgressWrapper>
      <div className="flex items-center w-full gap-3 mt-2 relative">
        <div className="absolute planet border">
          <div className="planet-icon flex items-center justify-center">
            <PlanerIcon height={24} width={24} className="white-text" />
          </div>
        </div>
        <div className="w-full progress-container">
          <div className="h-full progress-bar" style={{ width: `${getProgressWidth()}%` }}></div>
        </div>
        <div className="quicksand-700-text font-16 progress-label">
          {selectedPanelIndex}/{totalPanels}
        </div>
      </div>
    </FlowProgressWrapper>
  );
};

const FlowProgressWrapper = styled.div`
  .planet {
    z-index: 1;
    padding: 4px;
    border-radius: 50%;
    .planet-icon {
      border-radius: 50%;
      background: ${({ theme }) => theme.primary_500};
      padding: 4px;
      ::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }
  }
  .progress-container {
    z-index: 2;
    height: 8px;
    padding: 4px;
    border: 1px solid ${({ theme }) => theme.natural_200};
    border-left: none;
    border-radius: 100px;
    margin-left: 40px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    .progress-bar {
      transition: width 0.5s ease-in-out;
      background: linear-gradient(90deg, #78cbb8 4.66%, #edaa00 50.84%, #c86c61 100%);
      border-radius: 100px;
    }
  }
`;

export default FlowProgress;
