import React, { Fragment, useContext } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../helpers/utils';
import AddMeasurementBlockchain from './add-measurement-blockchain';
import BeforeStart from './before-start';

const PopupComponents = {
  'add-measurement-blockchain': AddMeasurementBlockchain,
  'before-start': BeforeStart,
};

const Modal = () => {
  const { setModal, modal } = useContext(OrganisationContext);

  const onClick = e => {
    if (e.target === e.currentTarget) {
      setModal(initModal);
    }
  };

  const ref = useOnclickOutside(
    () => {
      setModal(initModal);
    },
    {
      ignoreClass: `ignore-click`,
    },
  );

  const setPopupComponent = () => {
    const Component = PopupComponents[modal.type];
    if (Component) {
      return <Component />;
    }
    return <Fragment />;
  };

  if (!modal.type) return null;

  return (
    <CSSTransition appear classNames="modal-transition" in timeout={100}>
      <ModalWrapper
        id="modal-wrapper-id"
        onclick={onClick}
        style={{ justifyContent: modal.content?.top ? 'start' : 'center' }}>
        <CSSTransition key="modal-content-id" appear classNames="popup-in" in timeout={200}>
          <div
            key="modal-content-id"
            ref={ref}
            className="modal-content"
            id="modal-content-id"
            style={{
              borderRadius: modal.content?.noRadius ? '0' : '16px',
              marginTop: modal.content?.top ? '80px' : '0',
            }}>
            {setPopupComponent()}
          </div>
        </CSSTransition>
      </ModalWrapper>
    </CSSTransition>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 32, 48, 0.8);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .modal-content {
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
    overflow-y: visible !important;
    background: #ffffff;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-shadow: 2px 2px 50px rgba(5, 49, 73, 0.2);
    border-radius: 16px;
    outline: 0;
    /* padding: 40px; */
    position: relative;
    max-width: 100%;
  }

  .popup-in-appear {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-active {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in, opacity 50ms ease-in;
  }

  .popup-fade-appear-active {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }
  .popup-dashboard-fullscreen {
    height: 100vh;
    width: 100vw;
    padding: 20px;
  }
`;

export default Modal;
