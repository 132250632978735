import classNames from 'classnames';
import React from 'react';
import { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as DownIcon } from '../../../assets/images/arrow-right.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/toast-close.svg';

const defaultCustomStyle = {
  control: {},
  valueContainer: {
    display: 'flex',
  },
  menu: {},
  menuList: {},
};

const variants = {
  primary: {
    control: { height: '40px', borderRadius: '12px' },
  },
};

const Option = props => {
  const { innerProps, data, isSelected, selectProps } = props;
  const { getOptionLabel } = selectProps;

  return (
    <div
      className={classNames('flex items-center radius-2 px-4 py-3 cursor option-wrapper', isSelected && 'selected')}
      {...innerProps}>
      <span className={classNames('flex-1 lato-400-text font-16 option-text', isSelected && 'selected')}>
        {getOptionLabel(data)}
      </span>
    </div>
  );
};

const DropdownIndicator = props => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <components.DropdownIndicator {...props} className="mr-2">
      <DownIcon height={16} width={16} className={classNames(menuIsOpen ? 'rotate-270' : 'rotate-90')} />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = props => {
  return <CloseIcon height={16} width={16} onClick={props.clearValue} />;
};

const SearchableDropdown = ({
  placeholder = '',
  onChange = () => {},
  value = null,
  isMulti = false,
  name = '',
  sub_name = '',
  is_required = false,
  className = '',
  error = false,
  customStyle = defaultCustomStyle,
  customClass = false,
  menuPlacement = 'auto',
  inputValue = '',
  onInputChange = () => {},
  loadOptions = () => {},
  defaultAdditional = {},
  key = 'searchable_dropdown',
  isClearable = false,
  variant = '',
  isSearchable = true,
  loadOptionsOnMenuOpen = true,
  customComponent,
  ...rest
}) => {
  const theme = useTheme();

  const dropdownStyle = {
    control: (baseStyles, { selectProps: { menuIsOpen } }) => ({
      ...baseStyles,
      borderColor: error ? theme.additionalRed : menuIsOpen ? theme.focus_border : theme.lightGrey,
      borderRadius: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: '40px',
      cursor: 'pointer',
      boxShadow: 'none',

      '&:hover': {
        borderColor: error ? theme.error_500 : menuIsOpen ? theme.primary_500 : theme.lightGrey,
      },
      ...(variants[variant]?.control || {}),
      ...customStyle.control,
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontFamily: 'Inter',
      color: theme.natural_400,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: baseStyles => ({
      ...baseStyles,
      color: theme.black,
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      fontFamily: 'Lato',
      fontSize: '16px',
      padding: '4px 16px',
      ...customStyle.valueContainer,
    }),
    menu: baseStyles => ({
      ...baseStyles,
      borderRadius: '8px',
      overflow: 'hidden',
      zIndex: 11,
      ...customStyle.menu,
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: '8px',
      // overflow: 'hidden',
      maxHeight: '180px',
      ...customStyle.menuList,
    }),
    noOptionsMessage: baseStyles => ({
      ...baseStyles,
      fontFamily: 'Lato',
      color: theme.natural_400,
    }),
  };

  return (
    <SearchableDropdownWrapper className={classNames('w-full', className)}>
      {name && (
        <div className="mb-1">
          <span className="inter-500-text natural-900-text text-wrap">{name}</span>
          {sub_name && <span className="inter-400-text natural-400-text ml-1">{sub_name}</span>}
          {is_required && <span className="required-star ml-1">*</span>}
        </div>
      )}
      <AsyncPaginate
        isClearable={isClearable}
        isSearchable={isSearchable}
        key={key}
        openMenuOnClick={true}
        isMulti={isMulti}
        placeholder={placeholder}
        closeMenuOnSelect={!isMulti}
        value={value}
        inputValue={inputValue}
        onInputChange={onInputChange}
        loadOptions={loadOptions}
        additional={defaultAdditional}
        classNamePrefix={'searchable-select'}
        hideSelectedOptions={false}
        loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
        onChange={onChange}
        styles={dropdownStyle}
        menuPlacement={menuPlacement}
        components={{
          Option,
          DropdownIndicator,
          ClearIndicator,
          ...customComponent,
        }}
        {...rest}
      />
    </SearchableDropdownWrapper>
  );
};

export const SearchableDropdownWrapper = styled.div`
  .selected {
    background-color: ${({ theme }) => theme.primary_300};

    &:hover {
      background-color: ${({ theme }) => theme.primary_300};
    }
  }
`;

export default SearchableDropdown;
