import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

const PaymentSlider = ({ show, minOffset, measurementId }) => {
  const [added, setAdded] = useState(false);
  useEffect(() => {
    if (!added) {
      const script = document.createElement('script');

      script.src = `https://storage.googleapis.com/vizulink-sales-metacarbon-${process.env.REACT_APP_ENV}/static/js/index.js`;
      script.async = true;
      setAdded(true);
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [show]);

  return (
    <PaymentSliderWrapper>
      <div className={show ? 'side-drawer open' : 'side-drawer'}>
        <div
          className="flex flex-1 mxy-6"
          id="purchase_widget"
          data-widget="27baba7e-e8fb-11ee-9378-42010a9a0012"
          data-min={minOffset}
          data-measurement={measurementId}></div>
      </div>
    </PaymentSliderWrapper>
  );
};

const PaymentSliderWrapper = styled.div`
  .side-drawer {
    overflow-y: scroll;
    /* In commentaries are the way to make it slide from left to right */
    position: fixed;
    height: 100%;
    background: var(--Main-White, #fff);
    box-shadow: -10px 0px 50px 0px rgba(0, 0, 0, 0.16);
    top: 0;
    right: 0;
    /* right: 100%; */
    width: 550px;
    z-index: 200;
    transform: translateX(100%);
    /* transform: translateX(0%); */
    transition: transform 0.3s ease-out;
  }
  .side-drawer.open {
    transform: translateX(0);
    /* transform: translateX(100%); */
  }
`;

export default PaymentSlider;
