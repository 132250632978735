import React, { Fragment } from 'react';
import { ReactComponent as Loader } from '../../../assets/images/white-loader.svg';
import ButtonWrapper from './button.styled.js';

const Button = ({
  bgColor = '#BEF5EC',
  borderColor = '#BEF5EC',
  color = '#053149',
  disabled,
  icon = null,
  afterIcon = null,
  label = '',
  lableSize = false,
  onClick = () => {},
  size = 'small',
  width = '100%',
  borderRadius = '10px',
  borderWidth = '1px',
  loading = false,
  ...buttonProps
}) => {
  let height = '24px';
  let fontSize = '12px';

  switch (size) {
    case 'large':
      height = '48px';
      fontSize = '16px';
      break;
    case 'medium':
      height = '32px';
      fontSize = '14px';
      break;
    case 'tiny':
      height = '15px';
      break;
    case 'average':
      height = '40px';
      fontSize = '16px';
      break;
    case 'customsize':
      height = '44px';
      fontSize = '14px';
      break;
    default:
      break;
  }

  return (
    <ButtonWrapper
      bgColor={bgColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
      color={color}
      fontSize={fontSize}
      height={height}
      onClick={onClick}
      width={width}
      borderWidth={borderWidth}
      {...buttonProps}
      {...((disabled || loading) && {
        onClick: null,
        className: `${buttonProps.className} disabled`,
      })}>
      <div className="flex flex-1 items-center col-gap-1 justify-center h-full no-select px-2">
        {loading ? (
          <Loader className="white-text" />
        ) : (
          <Fragment>
            {icon && icon}
            {label && <label className={`lato-500-text one-line ${lableSize}`}>{label}</label>}
            {afterIcon && afterIcon}
          </Fragment>
        )}
      </div>
    </ButtonWrapper>
  );
};

export default Button;
