import React, { useContext, useState } from 'react';
import CommonPopup from './common-popup';
import { initModal } from '../helpers/utils';
import styled from 'styled-components';
import Button from '../components/common/button/button';
import { OrganisationContext } from '../context/organisationContext';

function BeforeStart() {
  const { setModal, modal } = useContext(OrganisationContext);
  const { onNext } = modal.content;
  const [step, setStep] = useState(1);
  return (
    <CommonPopup hideButtons={true}>
      <BeforeStartWrapper className="flex-column w-full">
        {step === 1 ? (
          <>
            <p className="quicksand-700-text font-32">Good data in means an accurate emissions estimate.</p>
            <p className="lato-400-text light-grey-text mt-1">Here’s the information you need to collect:</p>
            <p className="lato-700-text font-16 green-text mt-6">Company information:</p>
            <p className="lato-400-text mt-2 ml-4">Number of employees, annual revenue and size of facilities.</p>
            <p className="lato-700-text font-16 green-text mt-6">Scope 1 & 2:</p>
            <p className="lato-400-text mt-2 ml-4">Fuels, electricity costs, and information about company vehicles.</p>
            <p className="lato-700-text font-16 green-text mt-6">Scope 3:</p>
            <p className="lato-400-text mt-2 ml-4">
              Company expenses for transport, freight, ad spend, purchase goods etc.
            </p>
          </>
        ) : (
          <>
            <p className="quicksand-700-text font-32">Before we start</p>
            <p className="lato-400-text light-grey-text mt-1">
              The more sections you complete, the more accurate your footprint result will be.
            </p>
            <p className="lato-700-text font-16 green-text mt-6">1. Accuracy in the details</p>
            <p className="lato-400-text mt-2 ml-4">
              Prioritise your granular data first, as it provides the most accurate foundation for your emissions
              calculation.
            </p>
            <p className="lato-700-text font-16 green-text mt-6">2. Phone a friend</p>
            <p className="lato-400-text mt-2 ml-4">
              You might want to get help internally to detail the right information. Good data in means good data out!
            </p>
            <p className="lato-700-text font-16 green-text mt-6">3. We save as you go</p>
            <p className="lato-400-text mt-2 ml-4">
              Feel free to pick up where you left off or tweak anything you have already put in, we save as you go.
            </p>
          </>
        )}
        <div className="flex col-gap-4 mt-12">
          <Button
            className="default specified-width"
            width="330px"
            label="Cancel"
            size="large"
            borderRadius="16px"
            fontWeight="600"
            onClick={() => setModal(initModal)}
          />
          <Button
            className="primary specified-width"
            width="330px"
            label="Next"
            size="large"
            borderRadius="16px"
            fontWeight="600"
            onClick={() => {
              if (step === 1) setStep(2);
              else {
                setModal(initModal);
                onNext();
              }
            }}
          />
        </div>
      </BeforeStartWrapper>
    </CommonPopup>
  );
}

const BeforeStartWrapper = styled.div`
  width: 684px;
  max-width: 684px;
  padding: 32px;

  .green-text {
    color: ${({ theme }) => theme.primary_500};
  }
  .light-grey-text {
    color: ${({ theme }) => theme.purple_50};
  }
`;

export default BeforeStart;
