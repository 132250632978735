import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Toast from '../components/Toast/toast';
import Login from '../pages/login';
import Activate from '../pages/activate';
import ForgotPassword from '../pages/forgot-password';
import Main from '../pages/main/main';
import history from './history';
import { PrivateRoute } from './privateRoute';

export const Router = () => {
  const routes = createBrowserRouter([
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'activate',
      element: <Activate />,
    },
    {
      path: 'password',
      element: <ForgotPassword />,
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          path: '*',
          element: <Main />,
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider history={history} router={routes} />
      <Toast />
    </>
  );
};
