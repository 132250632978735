import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: JSON.parse(localStorage.getItem('user')),
    userDetails: JSON.parse(localStorage.getItem('user'))?.user,
    organisations: JSON.parse(localStorage.getItem('user'))?.organizations,
    organisation: JSON.parse(localStorage.getItem('user'))?.organizations?.[0],
    loading: false,
    error: {},
    forgotPasswordStep: 'EMAIL',
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
      state.loadingType = payload.loadingType;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.error = payload.error;
      state.errorType = payload.errorType;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
      state.userDetails = payload?.user;
      state.organisations = payload?.organisations;
      state.organisation = payload?.organisations?.[0]?.external_id;
    },
  },
});

export const getRequiredField = token => async dispatch => {
  return Axios.get(`${process.env.REACT_APP_API_URL}/users/activate/required_fields`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(({ data }) => {
      return Promise.resolve({ data });
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const activateUser =
  ({ token, request }) =>
  async dispatch => {
    return Axios.post(`${process.env.REACT_APP_API_URL}/users/activate`, request, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };

export const getPasswordRequirements = token => async dispatch => {
  return Axios.get(`${process.env.REACT_APP_API_URL}/users/password_requirements`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
