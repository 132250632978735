import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg';
import IconContainer from '../icon-container';
import CustomTooltip from '../tooltip-new';
import DateSelector from './date-selector';

const DateSelectorElement = ({
  selectedDate,
  setSelectedDate,
  placeholder,
  format = 'DD/MM/YYYY',
  className = '',
  minStartDate = null,
  maxEndDate = null,
  showCurrentMonth = false,
  name = '',
  is_required = false,
  error = false,
  isDisabled = false,
  isCalendarIcon = true,
  arrows = false,
  sub_name = '',
}) => {
  const uniqId = useRef(nanoid());
  const [showCalendar, setShowCalendar] = useState(false);

  const changeSelectedDate = date => {
    setSelectedDate(date);
    setShowCalendar(false);
  };

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowCalendar(false);
    }
  };

  return (
    <DateSelectorElementWrapper className="flex flex-1" tabIndex={1} onBlur={onBlur}>
      <CustomTooltip
        wrapperClassName="flex flex-1"
        id={`date-selector-${uniqId.current}`}
        clickable
        place="bottom"
        noArrow
        content={
          showCalendar &&
          !isDisabled && (
            <DateSelector
              setEndDate={() => {}}
              setStartDate={changeSelectedDate}
              startDate={moment.unix(selectedDate || moment().unix())}
              minStartDate={minStartDate}
              maxEndDate={maxEndDate}
              showCurrentMonth={showCurrentMonth}
            />
          )
        }
        isOpen={showCalendar}>
        <div className="flex-column flex-1">
          {name && (
            <div className="flex-1 mb-1">
              <span className="inter-500-text natural-900-text">{name}</span>
              {sub_name && <span className="inter-400-text natural-400-text ml-1">{sub_name}</span>}
              {is_required && <span className="required-star ml-1">*</span>}
            </div>
          )}
          <div
            className={classNames(
              'border flex flex-1 items-center justify-between px-4 cursor py-1 radius-2 date-value',
              showCalendar && 'show-calendar',
              error && !selectedDate && 'error-border',
              isDisabled && 'disabled',
              className,
            )}
            onClick={() => !isDisabled && setShowCalendar(!showCalendar)}
            tabIndex={0}>
            {selectedDate ? (
              <label className="lato-400-text natural-900-text font-24">
                {moment.unix(selectedDate).format(format)}
              </label>
            ) : (
              <label className="lato-400-text natural-400-text font-24">{placeholder}</label>
            )}
            {isCalendarIcon && (
              <IconContainer
                iconHeight={18}
                iconWidth={18}
                iconColor={showCalendar ? 'black' : 'natural_500'}
                Icon={CalendarIcon}
                backgroundColor={isDisabled ? 'transparent' : 'white'}
                iconContainerClassname="ml-2"
              />
            )}
          </div>
        </div>
      </CustomTooltip>
    </DateSelectorElementWrapper>
  );
};

const DateSelectorElementWrapper = styled.div`
  .date-value {
    min-height: 44px;
  }
  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: ${({ theme }) => theme.natural_100};
    border-color: ${({ theme }) => theme.natural_300};
  }
`;

export default DateSelectorElement;
