import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CO2Image } from '../../../assets/images/co2-image.svg';
import Illustrator from '../../../assets/images/login-illustrator.svg';
import { ReactComponent as LoginImageFrog } from '../../../assets/images/login-image-frog.svg';
import { ReactComponent as LoginImageMonkey } from '../../../assets/images/login-image-monkey.svg';
import { ReactComponent as LogoWhite } from '../../../assets/images/logo-white.svg';

const AuthContainer = ({ children }) => {
  return (
    <AuthContainerWrapper Illustrator={Illustrator} className="flex">
      <div className="flex flex-1 pxy-6">
        <div className="flex-column flex-1 pxy-10 logo-container overflow-scroll radius-6">
          <div className="flex-column flex-1">
            <LogoWhite />
            <div className="flex-column row-gap-2">
              <div className="flex justify-between">
                <label className="quicksand-700-text font-56 white-text">Welcome!</label>
                <CO2Image />
              </div>
              <label className="lato-400-text font-24 white-text">Business Carbon Emissions Calculator</label>
            </div>
            <div className="flex flex-1 my-10">
              <LoginImageMonkey />
              <LoginImageFrog style={{ marginTop: 45 }} />
            </div>
          </div>
          <div className="flex-column border-top pt-6 row-gap-3">
            <label className="lato-400-text font-16 primary-500-text">Guidance</label>
            <label className="lato-400-text white-text">Enter the required data to log in and start your journey</label>
          </div>
        </div>
        <div className="flex flex-1 items-center children-container overflow-scroll pl-10 pr-4">{children}</div>
      </div>
    </AuthContainerWrapper>
  );
};

const AuthContainerWrapper = styled.div`
  height: 100dvh;
  width: 100dvw;
  overflow: scroll;
  background-color: ${({ theme }) => theme.background};

  .logo-container {
    background-color: ${({ theme }) => theme.black};
    background-image: url(${({ Illustrator }) => Illustrator});
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    z-index: 1;
  }

  .children-container {
    min-width: 360px;
  }
`;

export default AuthContainer;
