import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import DonutPieChart from '../../components/charts/DonutPieChart';
import Button from '../../components/common/button/button';
import { getFormattedNumber } from '../../helpers/utils';
import { getCarbonOffset } from '../../store/features/dashboardSlice';
import { addToast } from '../../store/features/toastSlice';
import { ReactComponent as XRPIcon } from '../../assets/images/xrp.svg';
import PaymentSlider from '../../components/payment-slider';

const scopeNames = ['SCOPE_1', 'SCOPE_2', 'SCOPE_3'];

const DashboardChart = ({ scopes }) => {
  const { t } = useTranslation();

  const dataSet = useMemo(
    () =>
      scopes?.length > 0
        ? scopes?.map((scope, index) => ({
            key: scope.level,
            value: scope.total / 1000 || 0,
            name: t(scopeNames[index] ? scopeNames[index] : 'SCOPE'),
          }))
        : [],
    [scopes],
  );

  return (
    <DonutPieChart
      dataSet={dataSet}
      tooltipTitle={t('EMISSIONS')}
      tooltipValueFormatter={value => getFormattedNumber(value, 0, 0)}
    />
  );
};

const DashboardContent = ({ selectedMeasurement }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const customColors = useMemo(() => [theme.grey_400, theme.grey_600, theme.black, theme.grey_600], []);

  const [carbonOffset, setCarbonOffset] = useState({});
  const [offsetNow, setOffsetNow] = useState();

  const { form_session } = selectedMeasurement || {};
  const { scopes } = carbonOffset || {};
  let totalTonnes = scopes ? scopes?.reduce((acc, scope) => acc + (scope?.total || 0), 0) : 0;
  totalTonnes /= 1000;

  const onViewFullReport = () => {
    if (form_session) {
      navigate(`/measurement/${selectedMeasurement.id}/session/${form_session.id}`);
    }
  };

  const fetchCarbonOffset = async form_session => {
    dispatch(getCarbonOffset({ form_session_id: form_session.id, params: { country_code: 'GB' } }))
      .then(data => {
        setCarbonOffset(data);
      })
      .catch(error => {
        setCarbonOffset({});
        dispatch(addToast({ error: true, text: 'Error while fetching carbon offset', id: nanoid() }));
      });
  };

  useEffect(() => {
    if (selectedMeasurement && form_session) {
      fetchCarbonOffset(form_session);
    }

    return () => {
      setCarbonOffset({});
    };
  }, [selectedMeasurement, form_session]);

  const renderScope = (label, value, index = 0, showDot = true) => {
    return (
      <div className="flex-column row-gap-2 mb-6" key={index}>
        <div className="flex items-center">
          {showDot && (
            <div className="dot mr-2" style={{ backgroundColor: customColors[index % customColors.length] }} />
          )}
          <label className="lato-400-text font-16 flex-1">{label}</label>
        </div>
        <label className="quicksand-700-text font-32">{value}</label>
      </div>
    );
  };

  return (
    <DashboardContentWrapper className="flex-column mt-16 px-10">
      {offsetNow && (
        <PaymentSlider
          show={offsetNow || searchParams.get('redirect_status')}
          minOffset={totalTonnes * 1000}
          measurementId={selectedMeasurement?.id}
        />
      )}
      {offsetNow && <div className="backdrop" onClick={() => setOffsetNow(false)} />}
      <div className="flex">
        <div className="flex-column flex-1">
          <label className="lato-400-text font-16 purple-50-text border-bottom pb-4">
            {t('ESTIMATED_TOTAL_EMISSIONS')}
          </label>
          <div className="flex items-center justify-between one-line">
            <label className="quicksand-700-text">
              <span className="font-80 mr-1">{getFormattedNumber(totalTonnes)}</span>
              <span className="font-40">{t('TONNES_CO2')}</span>
            </label>
            {selectedMeasurement?.nft?.blockchain?.token?.hash && (
              <Button
                className="primary specified-width ml-4"
                size="medium"
                width="104px"
                borderRadius="10px"
                onClick={() => setOffsetNow(!offsetNow || searchParams.get('redirect_status'))}
                label={
                  selectedMeasurement?.transactions && selectedMeasurement?.transactions.length > 0
                    ? `${selectedMeasurement.transactions.reduce((a, t) => a + t.co2_kg_units, 0) / 1000} t`
                    : t('OFFSET_NOW')
                }
                disabled={selectedMeasurement?.transactions && selectedMeasurement?.transactions.length > 0}
              />
            )}
          </div>
          <div className="flex-column pxy-6 emission-detail-text mt-10">
            <label className="lato-400-text font-16">{t('EMISSION_ESTIMATION_DETAIL_TEXT_1')}</label>
            <label className="lato-400-text font-16 mt-4">{t('EMISSION_ESTIMATION_DETAIL_TEXT_2')}</label>
          </div>
          <div className="flex-column pxy-6 emission-detail-text mt-6">
            <label className="lato-400-text font-16">
              <span className="lato-700-text">Scope 1 = </span>
              {t('SCOPE_1_text')}
            </label>
            <label className="lato-400-text font-16">
              <span className="lato-700-text">Scope 2 = </span>
              {t('SCOPE_2_text')}
            </label>
            <label className="lato-400-text font-16">
              <span className="lato-700-text">Scope 3 = </span>
              {t('SCOPE_3_text')}
            </label>
          </div>
        </div>
        <div className="flex-column flex-1 ml-10">
          {/* {offsetNow ? (
            <div className="flex flex-1" id="purchase_widget" data-widget="27baba7e-e8fb-11ee-9378-42010a9a0012"></div>
          ) : (
            <> */}
          <label className="lato-400-text font-16 purple-50-text border-bottom pb-4">{t('YOUR_SCOPE_OVERVIEW')}</label>
          <div className="scope-container col-gap-8 mt-6">
            <div className="chart-container">
              <DashboardChart scopes={scopes} />
            </div>
            <div className="flex-column">
              {scopes?.map((scope, index) =>
                renderScope(
                  t(scopeNames[index] ? scopeNames[index] : 'SCOPE'),
                  getFormattedNumber(scope?.total / 1000 || 0, 0, 0),
                  index,
                ),
              )}
              <div className="flex items-center">
                <Button
                  className="default specified-width"
                  label={t('VIEW_FULL_REPORT')}
                  size="average"
                  borderRadius="12px"
                  width="150px"
                  onClick={onViewFullReport}
                />
                {selectedMeasurement?.nft?.blockchain && (
                  <Button
                    className="primary-white-black specified-width ml-4"
                    size="average"
                    width="150px"
                    borderRadius="12px"
                    label="XRP Token"
                    lableSize="12px"
                    onClick={() =>
                      window.open(
                        `https://test.bithomp.com/nft/${selectedMeasurement?.nft?.blockchain?.token?.hash}`,
                        '_blank',
                      )
                    }
                    icon={<XRPIcon height={16} width={27} />}
                  />
                )}
              </div>
            </div>
          </div>
          {/* </>
          )} */}
        </div>
      </div>
    </DashboardContentWrapper>
  );
};

const DashboardContentWrapper = styled.div`
  .emission-detail-text {
    border-radius: 16px;
    background-color: ${({ theme }) => theme.grey_500};
  }

  .scope-container {
    display: grid;
    grid-template-columns: 300px 1fr;

    .chart-container {
      width: 300px;
      height: 300px;
    }

    .dot {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      background-color: #d9d9d9;
    }
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    right: 0;
  }
`;

export default DashboardContent;
