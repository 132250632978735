import Axios from 'axios';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import HidePassword from '../../assets/images/hide-password.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import AuthContainer from '../../components/common/auth-container';
import Button from '../../components/common/button/button';
import { emailRegEx } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import { setUser } from '../../store/features/userSlice';

const Activate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const token = location.search.replace('?token=', '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [active, setActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [activateStatus, setActivateStatus] = useState('');
  const [serverError, setServerError] = useState();

  const onActivate = async event => {
    if (active) {
      if (password !== confirmPassword) {
        setActivateStatus('NO_MATCH');
      } else {
        try {
          event.preventDefault();
          const { data } = await Axios.post(
            `${process.env.REACT_APP_API_URL}/users/activate`,
            {
              password: password,
            },
            { headers: { authorization: `Bearer ${token}` } },
          );

          navigate('/login');
        } catch (e) {
          setShowError(true);
          const errorData = error?.response?.data;
          if (errorData?.error_code === 'FORBIDDEN') {
            setServerError('Token expired');
          }
        }
      }
    }
  };

  const changePassword = value => {
    setPassword(value);
    setShowError(false);
  };

  const changeConfirmPassword = value => {
    setConfirmPassword(value);
    setShowError(false);
  };

  useEffect(() => {
    setActive(confirmPassword && password && password.length > 2);
  }, [confirmPassword, password]);

  return (
    <AuthContainer>
      <LoginWrapper className="flex-1">
        <div className="flex-column login-form w-full gap-10">
          <div className="flex flex-column gap-2">
            <label className="quicksand-700-text font-32">{t('ACTIVATE_TITLE')}</label>
          </div>
          {(activateStatus === 'FAILURE' || activateStatus === 'NO_MATCH') && (
            <div className="error-message-alert items-center justify-between flex flex-1">
              <label className="quicksand-600-text font-16 error-text py-3">
                {activateStatus === 'NO_MATCH' ? t('PASSWORD_NO_MATCH') : t('INCORRECT_PASSWORD')}
              </label>
              <CloseIcon className="cursor" onClick={() => setActivateStatus('')} />
            </div>
          )}
          {showError && (
            <div className="flex error-message-alert items-center justify-between flex-1">
              <label className="quicksand-600-text font-16 error-text py-3">{t('INCORRECT_PASSWORD')}</label>
              <CloseIcon className="cursor" onClick={() => setShowError(false)} />
            </div>
          )}
          {serverError && (
            <div className="flex error-message-alert items-center justify-between flex-1">
              <label className="quicksand-600-text font-16 error-text py-3">{serverError}</label>
              <CloseIcon className="cursor" onClick={() => setServerError(null)} />
            </div>
          )}
          <div className="w-full flex gap-5 form flex-column items-center">
            <div className="w-full flex-column relative gap-5px">
              <div className="relative flex w-full">
                <input
                  autoComplete="off"
                  className={`input password-input w-full ${showError && 'error-border'}`}
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('PASSWORD')}
                  onChange={({ target: { value } }) => changePassword(value)}
                />
                <img
                  className="show-password"
                  src={showPassword ? HidePassword : ShowPassword}
                  alt="show password"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            </div>
            <div className="w-full flex-column relative gap-5px">
              <div className="relative flex w-full">
                <input
                  autoComplete="off"
                  className={`input password-input w-full ${showError && 'error-border'}`}
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder={t('CONFIRM_PASSWORD')}
                  onChange={({ target: { value } }) => changeConfirmPassword(value)}
                />
                <img
                  className="show-password"
                  src={showConfirmPassword ? HidePassword : ShowPassword}
                  alt="show password"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              </div>
            </div>
          </div>
          {/* Bottom Button  */}
          <div className="w-full flex flex-column gap-5 items-center">
            <Button
              borderRadius="16px"
              className={classNames('primary', !(active || showError) && 'disabled')}
              size="large"
              label={t('ACTIVATE')}
              onClick={onActivate}
            />
          </div>
          {/* end */}
        </div>
      </LoginWrapper>
    </AuthContainer>
  );
};

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;

  .form {
    .password-input {
      padding-right: 48px;
    }
    .show-password {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 16px;
      top: 20px;
      cursor: pointer;
    }

    a {
      text-decoration: none;
      align-self: center;
    }
  }
`;

export default Activate;
