import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${props => props.width};
  color: ${props => props.color};
  background: ${props => props.bgColor};
  height: ${props => props.height};
  border: ${props => props.borderWidth} solid ${props => props.borderColor};
  border-radius: ${props => props.borderRadius};
  cursor: pointer;

  * {
    cursor: pointer;
  }

  &:active {
    opacity: 0.8;
  }

  .after-icon {
    margin-right: 0;
    margin-left: 8px;
  }

  label {
    color: ${props => props.color};
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight || '500'};
  }

  &.primary {
    background: ${({ theme }) => theme.primary_500};
    border: 1px solid ${({ theme }) => theme.primary_500};

    label {
      color: #ffffff;
    }
  }

  &.default {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.primary_500};
    label {
      color: ${({ theme }) => theme.primary_500};
    }
  }

  &.transparent-default {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.natural_200};
    label {
      color: ${({ theme }) => theme.natural_900};
    }
  }

  &.secondary {
    background: ${({ theme }) => theme.primary_10};
    border: 1px solid ${({ theme }) => theme.primary_10};

    label {
      color: ${({ theme }) => theme.primary_500};
    }
  }

  &.back-to-summary {
    background: transparent;
    border: none;

    label {
      color: ${({ theme }) => theme.primary_500};
    }
  }

  &.transparent {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.primary_500};

    label {
      color: ${({ theme }) => theme.primary_500};
    }
  }

  &.negative {
    background: ${({ theme }) => theme.error_500};
    border: 1px solid ${({ theme }) => theme.error_500};

    label {
      color: #ffffff;
    }
  }

  &.primary-white {
    background: white;
    border: 1px solid white;

    label {
      color: ${({ theme }) => theme.primaryBlue};
    }
  }

  &.primary-white-black {
    background: white;
    border: 1px solid white;

    label {
      color: ${({ theme }) => theme.natural_900};
    }
  }

  &.primary-blue {
    background: ${({ theme }) => theme.primary_50};
    border: 1px solid ${({ theme }) => theme.primary_50};

    label {
      color: ${({ theme }) => theme.primaryBlue};
    }
  }

  &.primary-black {
    background: ${({ theme }) => theme.natural_900};
    border: 1px solid ${({ theme }) => theme.natural_900};

    label {
      color: white;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.primary-grey {
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.natural_200};

    label {
      color: ${({ theme }) => theme.natural_700};
    }
  }

  &.specified-width {
    width: ${({ width }) => width};
  }
`;

export default ButtonWrapper;
