import classNames from 'classnames';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { OrganisationContext } from '../../../context/organisationContext';

const switchVariants = {
  large: {
    width: '48px',
    height: '24px',
    selector_width: '20px',
    selector_height: '20px',
    transform: 'translate(24px)',
  },
  medium: {
    width: '36px',
    height: '20px',
    selector_width: '16px',
    selector_height: '16px',
    transform: 'translate(16px)',
  },
  small: {
    width: '32px',
    height: '16px',
    selector_width: '12px',
    selector_height: '12px',
    transform: 'translate(16px)',
  },
};

const Switch = ({ enabled, onClick, permission = '', disabled = false, className = '', variant = 'medium' }) => {
  const { checkPermission } = useContext(OrganisationContext);

  const onSwitchChange = () => {
    checkPermission(() => {
      onClick && onClick();
    }, permission);
  };

  return (
    <SwitchWrapper variant={switchVariants[variant]} className={className}>
      <div
        className={classNames(
          'flex items-center switch ml-1',
          enabled && 'switch-selected',
          disabled ? 'cursor-not-allowed' : 'cursor',
        )}
        onClick={e => {
          if (!disabled) {
            e.stopPropagation();
            onSwitchChange();
          }
        }}>
        <div className="selector" />
      </div>
    </SwitchWrapper>
  );
};

const SwitchWrapper = styled('div')`
  .switch {
    width: ${({ variant }) => variant.width};
    height: ${({ variant }) => variant.height};
    background: ${({ theme }) => theme.borderColor};
    border-radius: 12px;

    &:hover {
      background: ${({ theme }) => theme.borderColor};
    }

    .selector {
      min-width: ${({ variant }) => variant.selector_width};
      min-height: ${({ variant }) => variant.selector_height};
      background: #ffffff;
      border-radius: 50%;
      margin: 0 2px;
      transition: transform 400ms;
    }
  }

  .switch-selected {
    background: ${({ theme }) => theme.primary_500};

    &:hover {
      background: ${({ theme }) => theme.primary_500};
    }

    .selector {
      transform: ${({ variant }) => variant.transform};
    }
  }
`;

export default Switch;
