import React from 'react';
import { ReactComponent as RightIcon } from '../../assets/images/arrow-right.svg';
import Button from '../common/button/button';

const CalculatorAction = ({
  onNextWidgetClick,
  onPreviousWidgetClick,
  disabled = false,
  loading = false,
  end = false,
  firstTime = false,
  onBackToSummary = () => {},
}) => {
  return (
    <div className={`flex justify-between col-gap-6`}>
      {!firstTime ? (
        <Button
          className="back-to-summary specified-width flex items-center"
          width="184px"
          label="Back to Summary"
          size="large"
          borderRadius="16px"
          fontWeight="600"
          icon={<RightIcon className="rotate-180 primary-500-text" height={16} width={16} />}
          onClick={onBackToSummary}
        />
      ) : (
        <div />
      )}

      <div className="flex col-gap-4">
        <Button
          className="default specified-width"
          width="168px"
          label="Back"
          size="large"
          borderRadius="16px"
          fontWeight="600"
          onClick={onPreviousWidgetClick}
        />
        {end ? (
          <Button
            className="primary specified-width"
            width="168px"
            label="Complete"
            size="large"
            borderRadius="16px"
            fontWeight="600"
            onClick={onBackToSummary}
            disabled={disabled}
            loading={loading}
          />
        ) : (
          <Button
            className="primary specified-width"
            width="168px"
            label="Next"
            size="large"
            borderRadius="16px"
            fontWeight="600"
            onClick={onNextWidgetClick}
            disabled={disabled}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default CalculatorAction;
