import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import uuid from 'react-uuid';
import { ReactComponent as EditPensilIcon } from '../../assets/images/edit-pensil.svg';
import Illustrator from '../../assets/images/login-illustrator.svg';
import { ReactComponent as LogoWhite } from '../../assets/images/logo-white.svg';
import { ReactComponent as PlusRoundIcon } from '../../assets/images/plus-round.svg';
import Button from '../../components/common/button/button';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import { getMeasuments } from '../../store/features/dashboardSlice';
import { setCalculators, setFormSessionData, setMeansurement } from '../../store/features/calculatorSlice';

const DashboardHeader = ({ selectedMeasurement, setSelectedMeasurement }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.user);

  const { t } = useTranslation();
  const fetchChildOpt = async (
    search,
    _prevOptions,
    { page, merge, fetchFunction, hasMore, pageable, params = {}, payload = {}, defaultSelect = () => {} },
  ) => {
    if (!fetchFunction) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
    try {
      const optionData = await dispatch(
        fetchFunction({
          forFetchOnly: true,
          params: {
            page: page,
            search: search,
            ...params,
          },
          ...payload,
        }),
      );
      let optionContent = [];
      let last = true;
      if (pageable) {
        const { content, ...restResponse } = optionData || {};
        optionContent = optionData ? content : [];
        last = restResponse.last;
      } else {
        optionContent = optionData || [];
      }
      const changedOptions = optionContent.map((option, index) => ({
        ...option,
        value: option.id,
        optionIndex: index,
      }));

      if (page === 0 && changedOptions.length > 0) {
        defaultSelect(changedOptions[0]);
      }

      return {
        options: changedOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: !last,
          pageable,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: hasMore,
        additional: {
          page: page,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
  };

  const onEditData = () => {
    const { form_session, id, timing, name, currency, measurement_system } = selectedMeasurement || {
      form_session: null,
    };
    const { form } = form_session || { form: null };
    const { start_time, end_time } = timing || {};
    if (form_session && form && id) {
      navigate(`/measurements/${id}`);
    }
  };

  const logout = () => {
    localStorage.removeItem('user');
    window.location.reload();
  };
  return (
    <DashboardHeaderWrapper Illustrator={Illustrator} className="pxy-10">
      <div className="flex-column row-gap-6">
        <LogoWhite height={48} width={80} />
        <div className="flex-column">
          <label className="lato-400-text font-12 purple-50-text">{t('COMPANY_OVERVIEW')}</label>
          <label className="quicksand-700-text font-40 white-text">{user?.organizations?.[0]?.name}</label>
        </div>
        <div className="flex col-gap-8">
          <div className="flex-column row-gap-1">
            <label className="lato-400-text font-12 purple-50-text">{t('INDUSTRY')}</label>
            <label className="lato-400-text white-text">{selectedMeasurement?.industry?.industry}</label>
          </div>
          {/* <div className="flex-column row-gap-1">
            <label className="lato-400-text font-12 purple-50-text">{t('COUNTRY')}</label>
            <label className="lato-400-text white-text">{'United Kingdom of Great Britain and Nothern Ireland'}</label>
          </div> */}
        </div>
      </div>
      <div className="flex-column flex-1">
        <div className="flex justify-end">
          <Button className="default" size="medium" width="100px" label={t('LOG_OUT')} onClick={logout} />
        </div>
        <div className="flex-column flex-1 justify-end">
          <SearchableDropdown
            className="mb-6"
            isSearchable={false}
            defaultOptions={true}
            loadOptions={fetchChildOpt}
            placeholder="Select measurement"
            value={selectedMeasurement}
            onChange={setSelectedMeasurement}
            getOptionLabel={option => option.name}
            defaultAdditional={{
              page: 0,
              fetchFunction: getMeasuments,
              pageable: true,
              defaultSelect: a => (selectedMeasurement?.id ? {} : setSelectedMeasurement(a)),
            }}
          />
          <div className="flex col-gap-4">
            <Button
              className="default"
              icon={<PlusRoundIcon className="primary-500-text" />}
              size="medium"
              label={t('ADD_TIME_PERIOD')}
              onClick={() => {
                dispatch(setCalculators([]));
                dispatch(setFormSessionData([]));
                dispatch(setMeansurement({}));
                navigate(`/measurements/${uuid()}`, { state: { create: true } });
              }}
            />
            <Button
              className="default"
              icon={<EditPensilIcon className="primary-500-text" />}
              size="medium"
              label={t('EDIT_DATA')}
              onClick={onEditData}
            />
          </div>
        </div>
      </div>
    </DashboardHeaderWrapper>
  );
};

const DashboardHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.black};
  border-radius: 24px;
  background-image: url(${({ Illustrator }) => Illustrator});
  background-repeat: no-repeat;
  background-position: 0% 0%;

  display: grid;
  grid-template-columns: 2fr 350px;
  column-gap: 40px;
  min-width: fit-content;
  width: auto;
`;

export default DashboardHeader;
