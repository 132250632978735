import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getCarbonOffset } from '../../store/features/dashboardSlice';
import { getMeasurement } from '../../store/features/calculatorSlice';
import { addToast } from '../../store/features/toastSlice';
import TotalEmissionContent from './total-emission-content';
import TotalEmissionHeader from './total-emission-header';

const TotalEmission = () => {
  const dispatch = useDispatch();
  const { form_session_id, measurement_id } = useParams();

  const [carbonOffset, setCarbonOffset] = useState({});
  const [measurement, setMeasurement] = useState({});
  const [c, setC] = useState(0);

  const fetchCarbonOffset = async () => {
    dispatch(getCarbonOffset({ form_session_id, params: { country_code: 'GB' } }))
      .then(data => {
        setCarbonOffset(data);
      })
      .catch(error => {
        setCarbonOffset({});
        dispatch(addToast({ error: true, text: 'Error while fetching full report', id: nanoid() }));
      });
  };

  const fetchMeasurement = async () => {
    dispatch(getMeasurement({ measurement_id }))
      .then(data => {
        setMeasurement(data);
      })
      .catch(error => {
        setMeasurement({});
        dispatch(addToast({ error: true, text: 'Error while fetching measurement', id: nanoid() }));
      });
  };

  useEffect(() => {
    fetchCarbonOffset();
  }, [form_session_id]);

  useEffect(() => {
    fetchMeasurement();
  }, [measurement_id]);

  const onCreateNft = () => {
    dispatch(getMeasurement({ measurement_id }))
      .then(data => {
        setMeasurement(data);
        if (!data.nft?.blockchain) {
          setC(c + 1);
        }
      })
      .catch(error => {
        setMeasurement({});
        dispatch(addToast({ error: true, text: 'Error while fetching measurement', id: nanoid() }));
      });
  };

  useEffect(() => {
    if (c > 0 && c < 10 && !measurement.nft?.blockchain) {
      setTimeout(() => {
        fetchMeasurement();
        setC(c + 1);
      }, [c * 2000]);
    }
  }, [c, measurement]);

  return (
    <TotalEmissionWrapper className="flex-column flex-1 overflow-hidden pxy-6">
      <TotalEmissionHeader
        carbonOffset={carbonOffset}
        measurement={measurement}
        sessionId={form_session_id}
        onCreateNft={onCreateNft}
      />
      <TotalEmissionContent carbonOffset={carbonOffset} />
    </TotalEmissionWrapper>
  );
};

const TotalEmissionWrapper = styled.div``;

export default TotalEmission;
