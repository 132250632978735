import { createSlice } from '@reduxjs/toolkit';
import { defaultFirstPanel } from '../../helpers/utils';
import api from '../services/index';
import { all } from 'axios';

export const calculatorSlice = createSlice({
  name: 'calculator',
  initialState: {
    calculators: [],
    calculator: null,
    formDetails: {},
    formPanels: [],
    formWidgets: [defaultFirstPanel],
    measurement: {},
    formSessionData: [],
    campaigns: [],
    currencies: [],
    industries: [],
  },
  reducers: {
    setCalculators: (state, action) => {
      state.calculators = action.payload;
      state.calculator = action.payload?.[0] ?? null;
    },
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    setIndustries: (state, action) => {
      state.industries = action.payload;
    },
    setFormDetails: (state, action) => {
      state.formDetails = action.payload;
    },
    setFormPanels: (state, action) => {
      state.formPanels = action.payload;
    },
    setMeansurement: (state, action) => {
      state.measurement = action.payload;
    },
    setFormWidgets: (state, { payload }) => {
      const { merge, data } = payload;
      state.formWidgets = merge ? [...state.formWidgets, ...data] : data;
    },
    setFormSessionData: (state, action) => {
      state.formSessionData = action.payload;
    },
  },
});

const updateWidgetFlowComponents = (widgets, panel_id, allWidgets = []) => {
  try {
    const nextWidgetIdsToRemove =
      [...allWidgets, ...widgets]
        .map(w =>
          w.components
            ?.map(c =>
              c.flows.map(f => ({
                widget_id: f.next_widget.id,
                component_id: c.id,
                component_value: f.component_value,
                parent_widget_id: w.id,
                panel_id: f?.next_widget?.panel?.id,
              })),
            )
            ?.flat(),
        )
        ?.flat() || [];
    const widgetComponentWithFlows = widgets.map(w => ({
      ...w,
      panel: {
        id: panel_id,
      },
      flows: nextWidgetIdsToRemove.filter(f => f?.parent_widget_id === w.id),
      parents: nextWidgetIdsToRemove.filter(f => f?.widget_id === w.id),
    }));
    return widgetComponentWithFlows;
  } catch (error) {
    return widgets;
  }
};

export const getCalculators =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get('/calculators', { params });
      dispatch(setCalculators(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getFormDetails =
  ({ form_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/self-service/forms/${form_id}`);
      dispatch(setFormDetails(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getFormPanels =
  ({ form_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/self-service/forms/${form_id}/panels`);
      dispatch(setFormPanels(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPanelWidgets =
  ({ panel_id, form_widgets }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/self-service/panels/${panel_id}/widgets`);
      const updatedWidgets = updateWidgetFlowComponents(data, panel_id, form_widgets);
      dispatch(setFormWidgets({ data: updatedWidgets, merge: true }));
      return Promise.resolve(updatedWidgets);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createMeasurements =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/measurements`, request);
      dispatch(setMeansurement(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateMeasurement =
  ({ measurement_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/measurements/${measurement_id}`, request);
      dispatch(setMeansurement(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getMeasurement =
  ({ measurement_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/measurements/${measurement_id}`);
      dispatch(setMeansurement(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getCampaigns =
  ({ campaign_type }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/backoffice/campaigns?campaign_type=${campaign_type}`);
      dispatch(setCampaigns(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getCurrencies = () => async dispatch => {
  try {
    const { data } = await api.get(`/calculator/currencies`);
    dispatch(setCurrencies(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getIndustries = () => async dispatch => {
  try {
    const { data } = await api.get(`/calculator/industries`);
    dispatch(setIndustries(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createFormSession =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/form_sessions`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateFormSession =
  ({ form_session_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/form_sessions/${form_session_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const submitFormSessionData =
  ({ form_session_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/form_sessions/${form_session_id}/submission_data`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getFormSessionData =
  ({ form_session_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/form_sessions/${form_session_id}/submission_data`);
      dispatch(setFormSessionData(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createMeasurementNft =
  ({ measurement_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/measurements/${measurement_id}/nfts`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {
  setCalculators,
  setFormDetails,
  setFormPanels,
  setMeansurement,
  setFormWidgets,
  setFormSessionData,
  setCampaigns,
  setCurrencies,
  setIndustries,
} = calculatorSlice.actions;
export default calculatorSlice.reducer;
