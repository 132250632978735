import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const Radio = ({ label, value, checked, onChange, variant, ...rest }) => {
  return (
    <RadioWrapper className={classNames('flex items-center', variant)}>
      <input type="radio" className="radio" value={value} checked={checked} onClick={onChange} />
      <label className="quicksand-500-text ml-2">{label}</label>
    </RadioWrapper>
  );
};

const RadioGroup = ({
  onChange = () => {},
  value = null,
  options = [],
  name = '',
  showName = false,
  sub_name = '',
  className = '',
  variant = '',
  is_required = false,
  ...rest
}) => {
  return (
    <RadioGroupWrapper className={classNames('w-full flex-column flex-1 row-gap-2', className)}>
      {name && showName && (
        <div className="mb-1">
          <span className="inter-500-text natural-900-text text-wrap">{name}</span>
          {sub_name && <span className="inter-400-text natural-400-text ml-1">{sub_name}</span>}
          {is_required && <span className="required-star ml-1">*</span>}
        </div>
      )}
      <div className="flex-column flex-1 row-gap-3">
        {options.map((option, index) => (
          <Radio
            {...rest}
            key={index}
            value={option.id}
            label={option.name}
            checked={value?.id === option.id}
            onChange={() => onChange(value?.id === option.id ? null : option)}
            variant={variant}
          />
        ))}
      </div>
    </RadioGroupWrapper>
  );
};

const RadioGroupWrapper = styled.div``;

const RadioWrapper = styled.div`
  .radio {
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    outline: none;
    border: 1px solid ${({ theme }) => theme.natural_400};

    &::before {
      content: '';
      display: block;
      width: 60%;
      height: 60%;
      margin: 20% auto;
      border-radius: 50%;
    }

    &:checked::before {
      background: ${({ theme }) => theme.primary_500};
    }

    &:checked {
      border-color: ${({ theme }) => theme.primary_500};
    }
  }
`;

export default RadioGroup;
