export const TRANSLATIONS_EN = {
  EMAIL_ADDRESS: 'Email address',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  ENTER_PASSWORD: 'Enter your password',
  LOGIN: 'Login',
  FORGOTTEN_PASSWORD: 'Forgotten password',
  INCORRECT_EMAIL_PASSWORD: 'Incorrect email or password',
  INVALID_EMAIL_FORMAT: 'Please enter a valid email address',
  RECEIVE_CODE: 'Enter your email to receive the code',
  ENTER_EMAIL_ADDRESS: 'Enter your email address',
  WE_SENT_EMAIL: 'We sent you an email',
  SEND_EMAIL: 'Send email',
  SECURITY_REASON_DETAIL_TEXT:
    'For added security, we need to further verify your account. Please, enter security code from email here',
  INCORRECT_CODE_DETAIL_TEXT: 'Incorrect code please check your code is correct and try again',
  VERIFY: 'Verify',
  RESEND_SECURE_CODE: 'Resend secure code',
  RESET_YOUR_PASSWORD: 'Re-set your password',
  WELCOME_BACK: 'Welcome back',
  LOGIN_TO_YOUR_ACCOUNT: 'Log in to your account',
  LOGIN_SUCCESS: 'Login successful',
  COMPANY_OVERVIEW: 'Company overview',
  INDUSTRY: 'Industry',
  COUNTRY: 'Country',
  ESTIMATED_TOTAL_EMISSIONS: 'Estimated total emissions',
  YOUR_SCOPE_OVERVIEW: 'Your scope overview',
  EMISSION_ESTIMATION_DETAIL_TEXT_1:
    'Your emissions estimate relies on the data you have provided. Any omitted expenses may result in an incomplete representation of your actual total emissions.',
  EMISSION_ESTIMATION_DETAIL_TEXT_2:
    'Our calculation methodology is robust. We use the latest UK government factors. IEA and GHG protocols which follow best practice (we recommend 10% contingency is added to all calculations).',
  ADD_TIME_PERIOD: 'Add time period',
  EDIT_DATA: 'Edit data',
  VIEW_FULL_REPORT: 'View full report',
  TONNES_CO2: 'tonnes CO2',
  SCOPE_1: 'Scope 1 - Direct emissions',
  SCOPE_2: 'Scope 2 - Indirect emissions',
  SCOPE_3: 'Scope 3 - Supply chain emissions',
  EXPENSE_COVERAGE: 'Expense coverage (scope 3)',
  EMISSIONS: 'Emissions',
  NAME: 'Name',
  OFFSET_NOW: 'Offset now',
  SECTOR: 'Sector',
  PERCENT_OF_EMISSION_OF_SCOPE: '% emissions of scope',
  PERCENT_OF_EMISSION_OF_TOTAL: '% emissions of total',
  SCOPE_LEVEL: 'Scope {{level}}',
  SCOPE: 'Scope',
  COMPANY_NAME: 'Company name',
  MEASUREMENT_NAME: 'Measurement name',
  ACTIVATE: 'ACTIVATE',
  ACTIVATE_TITLE: 'Activate',
  INCORRECT_PASSWORD: 'Incorrect password',
  CONFIRM_PASSWORD: 'Confirm password',
  PASSWORD_NO_MATCH: 'Passwords do not match',
  LOG_OUT: 'Log out',
  FORGOTTEN_PASSWORD_TITLE: 'Forgotten your password?',
  FORGOTTEN_PASSWORD_TEXT: 'Enter your email below and we will send you a password re-set email',
  RESET: 'Reset',
  OTP_PASSWORD_TITLE: 'We sent you an email',
  OTP_PASSWORD_TEXT: 'Check your email and enter 6-digit code here',
  SET_PASSWORD: 'Set new password',
  SUBMIT: 'Submit',
  SCOPE_1_text: 'emissions you make directly',
  SCOPE_2_text: 'emissions you make indirectly through your sources of electricity',
  SCOPE_3_text: 'emissions you make indirectly that are associated with your suppliers and customers',
};
