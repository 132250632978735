import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as RightIcon } from '../../assets/images/arrow-right.svg';
import { ReactComponent as Loader } from '../../assets/images/black-loader.svg';
import { ReactComponent as XRPIcon } from '../../assets/images/xrp.svg';
import Button from '../../components/common/button/button';
import { OrganisationContext } from '../../context/organisationContext';
import { getFormattedNumber } from '../../helpers/utils';
import PaymentSlider from '../../components/payment-slider';

const TotalEmissionHeader = ({ carbonOffset, measurement, sessionId, onCreateNft }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setModal } = useContext(OrganisationContext);
  const [showOffsetNow, setShowOffsetNow] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const { scopes } = carbonOffset || {};
  let totalTonnes = scopes ? scopes?.reduce((acc, scope) => acc + (scope?.total || 0), 0) : 0;
  totalTonnes /= 1000;

  const offsetNow = () => {
    setModal({
      type: 'add-measurement-blockchain',
      content: {
        top: '116px',
        measurement: measurement,
        session_id: sessionId,
        onCreate: onCreateNft,
      },
    });
  };

  const onClick = () => {
    if (measurement?.nft?.blockchain)
      window.open(`https://test.bithomp.com/nft/${measurement?.nft?.blockchain?.token?.hash}`, '_blank');
    else offsetNow();
  };

  return (
    <TotalEmissionHeaderWrapper className="pxy-6">
      {showOffsetNow && (
        <PaymentSlider
          show={showOffsetNow || searchParams.get('redirect_status')}
          minOffset={totalTonnes * 1000}
          measurementId={measurement?.id}
        />
      )}
      {showOffsetNow && <div className="backdrop" onClick={() => setShowOffsetNow(false)} />}
      <div className="flex-column flex-1 pxy-4">
        <label className="lato-400-text font-16 purple-50-text pb-4 border-bottom">
          {t('ESTIMATED_TOTAL_EMISSIONS')}
        </label>
        <div className="flex justify-between">
          <div className="flex flex-1">
            <label className="quicksand-700-text white-text">
              <span className="font-80 mr-2">{getFormattedNumber(totalTonnes)}</span>
              <span className="font-28">{t('TONNES_CO2')}</span>
            </label>
          </div>
          <div className="flex self-end mb-6">
            {measurement?.nft?.blockchain?.token?.hash && (
              <Button
                className="primary specified-width mr-4"
                size="medium"
                width="104px"
                borderRadius="10px"
                label={
                  measurement?.transactions && measurement?.transactions.length > 0
                    ? `${measurement.transactions.reduce((a, t) => a + t.co2_kg_units, 0) / 1000} t`
                    : t('OFFSET_NOW')
                }
                onClick={() => setShowOffsetNow(!showOffsetNow || searchParams.get('redirect_status'))}
                disabled={measurement?.transactions && measurement?.transactions.length > 0}
              />
            )}
            <Button
              className="primary-white-black specified-width"
              label={
                measurement.nft && !measurement.nft?.blockchain
                  ? 'Minting'
                  : measurement.nft?.blockchain
                  ? 'XRP Token'
                  : 'XRP Mint'
              }
              size="medium"
              width="110px"
              borderRadius="10px"
              icon={
                measurement.nft && !measurement.nft?.blockchain ? (
                  <Loader className="black-text" />
                ) : (
                  <XRPIcon height={16} width={27} />
                )
              }
              onClick={onClick}
              disabled={measurement.form_session?.session_end && !measurement.nft?.blockchain}
            />
            {/* <Button
              className="primary specified-width ml-4"
              size="medium"
              width="104px"
              borderRadius="10px"
              label={t('OFFSET_NOW')}
            /> */}
          </div>
        </div>
      </div>
      <div className="flex justify-between flex-1 ml-22">
        <div className="flex-column pxy-4 self-end">
          {/* <div className="flex-column row-gap-1">
            <label className="lato-400-text font-12 purple-50-text">{t('COUNTRY')}</label>
            <label className="lato-400-text white-text">{'United Kingdom of Great Britain and Nothern Ireland'}</label>
          </div> */}
          <div className="flex-column row-gap-1 mt-4">
            <label className="lato-400-text font-12 purple-50-text">{t('SECTOR')}</label>
            <label className="lato-400-text white-text">{measurement?.industry?.industry}</label>
          </div>
        </div>
        <div className="flex-clumn">
          <div
            className="flex items-center cursor no-select"
            onClick={() => navigate('/dashboard', { replace: true, state: { measurement: measurement } })}>
            <RightIcon className="rotate-180 primary-500-text" height={16} width={16} />
            <label className="font-12 primary-500-text ml-2">Back to Summary</label>
          </div>
        </div>
      </div>
    </TotalEmissionHeaderWrapper>
  );
};

const TotalEmissionHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.black};
  border-radius: 24px;
  display: flex;

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    right: 0;
  }
`;

export default TotalEmissionHeader;
