import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ClockIcon from '../../assets/images/clock.svg';
import { defaultFirstPanel } from '../../helpers/utils';

const PanelHeader = ({ panel, selectedPanel, setCurrentPanel }) => {
  const { id, name } = panel;
  const isSelectedPanel = id === selectedPanel?.id || (selectedPanel == null && panel.id === 'TIME_PERIOD');

  return (
    <PanelHeaderWrapper
      className={classNames('flex-column pt-2 pb-4 cursor', isSelectedPanel && 'selected-panel')}
      onClick={() => setCurrentPanel(panel)}>
      <div
        className={classNames(isSelectedPanel ? 'white-text quicksand-600-text' : 'purple-50-text quicksand-500-text')}>
        {name}
      </div>
    </PanelHeaderWrapper>
  );
};

const PanelDetails = ({ panel, widget }) => {
  const { name, icon } = panel || {};
  return (
    <PanelDetailsWrapper className="py-12 flex-column justify-between flex-1">
      <label className="white-text quicksand-700-text font-56">{widget?.name}</label>
      <div className="flex justify-end items-center">
        <img src={widget?.icon?.url || icon?.url || ClockIcon} height={248} alt="panel-icon" />
      </div>
    </PanelDetailsWrapper>
  );
};

const PanelGuidance = ({ panel, widget }) => {
  const { guidance, name } = panel || {};
  return (
    <PanelGuidanceWrapper>
      <div className="flex-column border-top pt-6 row-gap-3">
        <label className="lato-400-text font-16 primary-500-text">Guidance</label>
        <label className="lato-400-text white-text">{widget.guidance || guidance || name}</label>
      </div>
    </PanelGuidanceWrapper>
  );
};

const CalculatorLeft = ({ widget, setCurrentPanel }) => {
  const { formPanels } = useSelector(state => state.calculator);
  const selectedPanel = widget?.panel;

  const selectedPanelData = formPanels.find(panel => panel.id === selectedPanel?.id) || defaultFirstPanel;
  const ref = useRef();

  const scrollToElement = () => {
    if (!ref) return;
    const { current } = ref;
    if (current !== null) {
      current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(scrollToElement, [selectedPanel]);
  return (
    <CalculatorLeftWrapper className="radius-6 pxy-10 flex-column no-select">
      <div className="flex justify-between border-bottom form-panel-header">
        {[defaultFirstPanel, ...formPanels].map(panel => (
          <div className="mr-8" ref={panel.id === selectedPanel?.id ? ref : null}>
            <PanelHeader key={panel.id} panel={panel} selectedPanel={selectedPanel} setCurrentPanel={setCurrentPanel} />
          </div>
        ))}
      </div>
      <PanelDetails panel={selectedPanelData} widget={widget} />
      <PanelGuidance panel={selectedPanelData} widget={widget} />
    </CalculatorLeftWrapper>
  );
};

const CalculatorLeftWrapper = styled.div`
  background-color: ${({ theme }) => theme.black};
  width: 50%;
  min-width: 360px;
  .form-panel-header {
    white-space: nowrap;
    overflow-x: scroll;
    height: 42px;
  }
`;

const PanelHeaderWrapper = styled.div`
  &.selected-panel {
    border-bottom: 2px solid #ffffff;
  }
`;

const PanelDetailsWrapper = styled.div``;

const PanelGuidanceWrapper = styled.div`
  height: 100px;
`;

export default CalculatorLeft;
