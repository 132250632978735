import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { defaultFirstPanel } from '../../helpers/utils';
import {
  getCalculators,
  getFormDetails,
  getFormPanels,
  getFormSessionData,
  getPanelWidgets,
  setCalculators,
  setFormDetails,
  setFormPanels,
  setFormSessionData,
  setFormWidgets,
  setMeansurement,
  updateFormSession,
  getMeasurement,
} from '../../store/features/calculatorSlice';
import { addToast } from '../../store/features/toastSlice';
import CalculatorLeft from './calculator-left';
import CalculatorRight from './calculator-right';

const Calculator = () => {
  const dispatch = useDispatch();

  const { measurement_id } = useParams();
  const { state } = useLocation();

  const { calculator, formPanels, formWidgets, measurement } = useSelector(state => state.calculator);

  const [currentWidget, setCurrentWidget] = useState(defaultFirstPanel);
  const [end, setEnd] = useState(false);

  const fetchCalculatorData = () => {
    dispatch(getCalculators({ tag: 'SME' })).catch(() =>
      dispatch(addToast({ error: true, text: 'Error while fetching calculators', id: nanoid() })),
    );
  };

  const fetchFormDetails = calculator => {
    const { form } = calculator;
    if (!form) return;
    dispatch(getFormDetails({ form_id: form.id })).catch(() =>
      dispatch(addToast({ error: true, text: 'Error while fetching form details', id: nanoid() })),
    );
  };

  const fetchFormPanels = calculator => {
    const { form } = calculator;
    if (!form) return;
    dispatch(getFormPanels({ form_id: form.id })).catch(() =>
      dispatch(addToast({ error: true, text: 'Error while fetching form panels', id: nanoid() })),
    );
  };

  const fetchFormPanelWidgets = (panel, widget_id) => {
    if (!panel?.id) {
      return;
    }
    const panelWidget = formWidgets.find(
      widget => widget?.panel?.id === panel.id && (!widget_id || widget?.id === widget_id),
    );
    if (panelWidget) {
      setCurrentWidget(panelWidget);
      return;
    }
    dispatch(getPanelWidgets({ panel_id: panel.id, form_widgets: formWidgets }))
      .then(data => {
        if (data?.length) {
          setCurrentWidget(widget_id ? data.find(d => d.id === widget_id) : data[0]);
        }
      })
      .catch(() => dispatch(addToast({ error: true, text: 'Error while fetching form panel widgets', id: nanoid() })));
  };

  const reOpenFormSession = () => {
    if (!measurement.id) {
      return;
    }
    dispatch(updateFormSession({ form_session_id: measurement.form_session.id, request: { session_end: '' } }))
      .then(data => {
        // dispatch(setMeansurement({ id: measurement_id, form_session: data }));
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'Error while reopening form session', id: nanoid() }));
      });
  };

  const fetchFormSessionData = form_session_id => {
    if (!form_session_id) {
      return;
    }
    dispatch(getFormSessionData({ form_session_id })).catch(() => {
      dispatch(setFormSessionData([]));
    });
  };

  useEffect(() => {
    if (measurement.id && !state?.create) {
      reOpenFormSession();
      fetchFormSessionData(measurement.form_session.id);
    } else {
      fetchCalculatorData();
    }
    return () => {
      // dispatch(setCalculators([]));
      // dispatch(setFormSessionData([]));
      // dispatch(setMeansurement({}));
    };
  }, [measurement?.id, state]);

  useEffect(() => {
    if (measurement.id) {
      fetchFormDetails({ form: { id: measurement.form_session.form.id } });
      fetchFormPanels({ form: { id: measurement.form_session.form.id } });
    } else {
      if (!calculator) return;
      fetchFormDetails(calculator);
      fetchFormPanels(calculator);
    }

    return () => {
      dispatch(setFormDetails({}));
      dispatch(setFormPanels([]));
      dispatch(setFormWidgets({ data: [defaultFirstPanel], merge: false }));
    };
  }, [calculator, measurement]);

  const changeNextPanel = (panel_id, widget_id) => {
    if (panel_id) {
      const nextPanel = formPanels.find(panel => panel.id === panel_id);
      fetchFormPanelWidgets(nextPanel, widget_id);
    } else {
      const currentPanelId = currentWidget?.panel?.id;
      const panelIndex = formPanels.findIndex(panel => panel.id === currentPanelId);
      if (panelIndex === formPanels.length - 1) {
        return;
      }
      const nextPanel = formPanels[panelIndex + 1];
      fetchFormPanelWidgets(nextPanel);
    }
  };

  useEffect(() => {
    setEnd(
      currentWidget?.panel?.id === formPanels?.[formPanels.length - 1]?.id &&
        formWidgets?.[formWidgets.length - 1]?.id === currentWidget?.id,
    );
  }, [currentWidget]);

  const setCurrentPanel = panel => {
    fetchFormPanelWidgets(panel);
  };
  useEffect(() => {
    if (measurement_id && !state?.create) dispatch(getMeasurement({ measurement_id: measurement_id }));
  }, [measurement_id, state]);

  return (
    <CalculatorWrapper className="pxy-6 flex flex-1 col-gap-10">
      <CalculatorLeft widget={currentWidget} setCurrentPanel={setCurrentPanel} />
      <CalculatorRight
        changeNextPanel={changeNextPanel}
        currentWidget={currentWidget}
        setCurrentWidget={setCurrentWidget}
        end={end}
      />
    </CalculatorWrapper>
  );
};

const CalculatorWrapper = styled.div`
  background-color: ${({ theme }) => theme.background};
  overflow: scroll;
`;

export default Calculator;
