import classNames from 'classnames';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorDescription } from '../../helpers/utils';
import { submitFormSessionData } from '../../store/features/calculatorSlice';
import { addToast } from '../../store/features/toastSlice';
import DateSelectorElement from '../common/date-selector/date-selector-element';
import ComponentHeader from '../common/header';
import ComponentInput from '../common/input';
import RadioGroup from '../common/radio-group';
import SelectDropdown from '../common/select-dropdown';
import CalculatorAction from './calculator-action';
import { useQuery } from '../../helpers/useQuery';

const componentMap = {
  DATE_SELECTOR: { component: DateSelectorElement },
  HEADER: { component: ComponentHeader },
  NUMERIC: { component: ComponentInput, props: { type: 'number' } },
  CHOOSER: {
    component: SelectDropdown,
  },
  RADIO: {
    component: RadioGroup,
  },
  ALPHANUMERIC: { component: ComponentInput },
  DEFAULT: { component: () => <Fragment /> },
};

const CalculatorComponent = ({
  previous,
  next,
  componentGroupLength,
  component,
  value,
  onChange,
  setWidgetData,
  widgetData,
  measurement,
  maxLength,
  firstTime,
}) => {
  const { component_type, component_layout, id, name, label, sub_label, font, list } = component;
  const Component = componentMap[component_layout] || componentMap[component_type] || componentMap['DEFAULT'];
  return (
    <div className="px-1 w-full flex-column row-gap-4" style={{ flex: `1 1 calc(100%/${componentGroupLength})` }}>
      <Component.component
        {...component}
        key={id}
        name={label}
        showName={!!label}
        placeholder={sub_label || label || name}
        options={list?.choices}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        onChange={value => onChange(value, component.id)}
        value={value}
        {...Component.props}
        currency={measurement.currency?.currency_code}
        measurementSystem={measurement.measurement_system}
        previous={previous}
        next={next}
        maxLength={maxLength}
      />
    </div>
  );
};

const CalculatorWidgets = ({
  widget,
  setWidgetData,
  widgetData,
  className = '',
  onNextWidgetClick,
  onPreviousWidgetClick,
  showActions = true,
  componentClassName = '',
  onBackToSummary,
  end,
  firstTime,
}) => {
  const dispatch = useDispatch();
  const { measurement } = useSelector(state => state.calculator);
  const { form_session } = measurement || {};
  const { components, id } = widget || {};
  const widgetValues = widgetData[id] || {};

  const [loading, setLoading] = useState(false);

  const onChange = (value, key) => {
    setWidgetData({ ...widgetData, [id]: { ...widgetValues, [key]: value } });
  };

  const groupByRow = components => {
    return components
      ? components
          ?.filter(c => c.is_visible)
          .reduce((acc, curr) => ({ ...acc, [curr.row]: (acc[curr.row] || []).concat(curr) }), {})
      : {};
  };

  const componentsByRow = Object.values(groupByRow(components));
  const maxLength = componentsByRow
    .flat()
    .filter(c => c.component_type === 'NUMERIC')
    .map(c => c.label)
    .map(l => l.length)
    .reduce((prev, current) => (prev && prev > current ? prev : current), 0);

  const numericComponents = components.filter(
    ({ component_type, is_visible }) => component_type === 'NUMERIC' && is_visible,
  );
  const isNextEnabled =
    (numericComponents.length > 0 && numericComponents.every(component => widgetValues[component.id])) ||
    components
      .filter(
        ({ component_type, is_visible }) => component_type !== 'HEADER' && component_type !== 'NUMERIC' && is_visible,
      )
      .some(component => widgetValues[component.id]);

  const createFormSubmissionRequest = values => {
    const formComponents = components
      ?.filter(({ is_visible, component_type }) => is_visible && component_type !== 'HEADER')
      .map(({ flows, ...rest }) => ({
        ...rest,
        value: values[rest.id],
      }));

    const formSubmissionRequest = formComponents
      ?.reduce((acc, component) => {
        let componentRequest = [];
        const { lexicon, id, component_type, value } = component || {};
        if (component_type === 'CHOOSER') {
          componentRequest = [
            {
              panel_widget_component: {
                id: id,
              },
              submitted_data: value?.id || '',
              lexicon: `${lexicon}.id`,
            },
            {
              panel_widget_component: {
                id: id,
              },
              submitted_data: value?.name || '',
              lexicon: `${lexicon}.name`,
            },
          ];
        } else {
          componentRequest = [
            {
              panel_widget_component: {
                id: id,
              },
              submitted_data: value || '',
              lexicon,
            },
          ];
        }
        return [...acc, ...componentRequest];
      }, [])
      .filter(({ submitted_data }) => !!submitted_data);
    return formSubmissionRequest;
  };

  const onNextClick = () => {
    if (loading) return;
    if (!form_session?.id) {
      dispatch(addToast({ error: true, text: 'Form session is not available', id: 'form-session' }));
      return;
    }
    setLoading(true);
    dispatch(
      submitFormSessionData({ request: createFormSubmissionRequest(widgetValues), form_session_id: form_session?.id }),
    )
      .then(() => onNextWidgetClick())
      .catch(error => {
        const errorText = getErrorDescription(error, 'Something went wrong while submitting form data');
        dispatch(addToast({ error: true, text: errorText, id: 'form-session-data' }));
      })
      .then(() => setLoading(false));
  };

  return (
    <Fragment>
      <div className={classNames('flex-1', className)}>
        {componentsByRow.map((componentGroup, index) => (
          <div key={index} className={classNames('flex flex-1 w-full device-scale-component', componentClassName)}>
            {componentGroup.map((component, i) => (
              <CalculatorComponent
                key={component.id}
                componentGroupLength={componentGroup.length}
                component={component}
                value={widgetValues[component.id]}
                onChange={onChange}
                setWidgetData={setWidgetData}
                widgetData={widgetData}
                previous={index > 0 ? componentsByRow[index - 1][0] : null}
                next={index + 1 < componentsByRow.length ? componentsByRow[index + 1][0] : null}
                measurement={measurement}
                maxLength={maxLength}
                firstTime={firstTime}
              />
            ))}
          </div>
        ))}
      </div>
      {showActions && (
        <CalculatorAction
          onNextWidgetClick={onNextClick}
          onPreviousWidgetClick={onPreviousWidgetClick}
          disabled={false}
          loading={loading}
          onBackToSummary={onBackToSummary}
          end={end}
          firstTime={firstTime}
        />
      )}
    </Fragment>
  );
};

export default CalculatorWidgets;
